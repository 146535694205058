import React from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import userStore from '../../stores/userStore'
import { useOutletContext } from 'react-router-dom'

export default function TransporterFilterTab({billOfilterProps}: {billOfilterProps: any}) {
    const {
        handleSelectAll,
        filteringData,
        setFilteringData,
        isTransporter,
        filterConnaissement,
        isFiltering,
        isEmpty,
        isLoading,
      } = billOfilterProps
    
      const { naviresData } = useOutletContext<any>();
    
      const dataStore = userStore((state: any) => state)
    
    //   const status = isTransporter ? [
      
    //     'OFFICIALISE',
    //     'OFFICIALISE_SOUS_RESERVE',
       
    //   ] : [
    //     'BROUILLON',
    //     'DEMANDE',
    //     'DEMANDE_REFUSEE',
    //     'SAISIE',
    //     'OFFICIALISE',
    //     'OFFICIALISE_SOUS_RESERVE',
    //     'MODIFIE',
    //     'PRIS_EN_CHARGE',
    //     'EMBARQUE',
    //     'EMBARQUEMENT_REFUSE',
    //     'TRANSFERE',
    //     'ANNULE',
    //   ]
    
      return (
        <React.Fragment>
        <thead className='d-table-head'>
          <tr>
         
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='N°Connaisse...'
                name='numeroConnaissement'
                value={filteringData.numeroConnaissement || ''}
                onChange={(e) => {
                  const updatedFilteringData = {
                    ...filteringData,
                    numeroConnaissement: e.currentTarget.value,
                  }
                  setFilteringData(updatedFilteringData)
                }}
              />
            </th>
            {isTransporter && (
              <th className='responsive-font-small p-1 p-sm-2'>
                {' '}
                <Form.Control
                  className=' border'
                  type='text'
                  autoComplete='on'
                  placeholder='Compagnie'
                  name='expediteur_denomination'
                  value={filteringData.expediteur_denomination || ''}
                  onChange={(e) => {
                    const updatedFilteringData = {
                      ...filteringData,
                      expediteur_denomination: e.currentTarget.value,
                    }
                    setFilteringData(updatedFilteringData)
                  }}
                />
              </th>
            )}
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Client'
                name='destinataire'
                value={filteringData.destinataire || ''}
                onChange={(e) => {
                  const updatedFilteringData = {
                    ...filteringData,
                    destinataire: e.currentTarget.value,
                  }
                  setFilteringData(updatedFilteringData)
                }}
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Select
                name='idNavire'
                value={filteringData.idNavire || ''}
                onChange={(e) => {
                  const updatedFilteringData = {
                    ...filteringData,
                    idNavire: e.currentTarget.value,
                  }
                  setFilteringData(updatedFilteringData)
                }}
                aria-label='zone'
                className=''
                required
              >
                <option value='' className='text-ui-second'>
                  Navire
                </option>
                {naviresData?.map((navire: any, index: any) => (
                  <option key={index} value={navire?.id}>
                    {navire?.name}
                  </option>
                ))}
              </Form.Select>
            </th>
           
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Date départ'
                name='date_depart'
                value={filteringData.dateDepart || ''}
                onChange={(e) => {
                  const updatedFilteringData = {
                    ...filteringData,
                    dateDepart: e.currentTarget.value,
                  }
                  setFilteringData(updatedFilteringData)
                }}
              />
            </th>
          
            <th className='responsive-font-small p-1 p-sm-2'>
              <Button
                // variant="transparent"
                className='d-flex align-items-center font-85 bg-remora-primary border-remora-primary'
                onClick={() => filterConnaissement(dataStore.access_token, filteringData)}
                disabled={isEmpty}
              >
                {isLoading ? 
              <Spinner size="sm" animation="border" role="status" className='me-2'/>
              : 
              <i className='ri-search-line me-0  me-md-2'></i>
            }
                <span className='d-none d-md-block'>Rechercher</span>{' '}
              </Button>
              {isFiltering && (
                <span
                  className='pointer'
                  onClick={() => {
                    setFilteringData({
                      numeroConnaissement: '',
                      expediteur_denomination: '',
                      destinataire: '',
                      idNavire: '',
                      evenementConnaissement: '',
                      dateDepart: '',
                      nomIleArrivee: '',
                      dateArrivee: '',
                    })
                  }}
                >
                  <i className='ri-close-line fs-5'></i>
                  <u>Réinitialiser</u>
                </span>
              )}
            </th>
          </tr>
        </thead>
         <thead className=' mobile-table-head'>
          <tr>
          
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='N°'
                name='numeroConnaissement'
                value={filteringData.numeroConnaissement || ''}
                onChange={(e) => {
                  const updatedFilteringData = {
                    ...filteringData,
                    numeroConnaissement: e.currentTarget.value,
                  }
                  setFilteringData(updatedFilteringData)
                }}
              />
            </th>
            {isTransporter && (
              <th className='responsive-font-small p-1 p-sm-2'>
                {' '}
                <Form.Control
                  className=' border'
                  type='text'
                  autoComplete='on'
                  placeholder='Compagnie'
                  name='expediteur_denomination'
                  value={filteringData.expediteur_denomination || ''}
                  onChange={(e) => {
                    const updatedFilteringData = {
                      ...filteringData,
                      expediteur_denomination: e.currentTarget.value,
                    }
                    setFilteringData(updatedFilteringData)
                  }}
                />
              </th>
            )}
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Client'
                name='destinataire'
                value={filteringData.destinataire || ''}
                onChange={(e) => {
                  const updatedFilteringData = {
                    ...filteringData,
                    destinataire: e.currentTarget.value,
                  }
                  setFilteringData(updatedFilteringData)
                }}
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Select
                name='idNavire'
                value={filteringData.idNavire || ''}
                onChange={(e) => {
                  const updatedFilteringData = {
                    ...filteringData,
                    idNavire: e.currentTarget.value,
                  }
                  setFilteringData(updatedFilteringData)
                }}
                aria-label='zone'
                className=''
                required
              >
                <option value='' className='text-ui-second'>
                  Navire
                </option>
                {naviresData?.map((navire: any, index: any) => (
                  <option key={index} value={navire?.id}>
                    {navire?.name}
                  </option>
                ))}
              </Form.Select>
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Date départ'
                name='date_depart'
                value={filteringData.dateDepart || ''}
                onChange={(e) => {
                  const updatedFilteringData = {
                    ...filteringData,
                    dateDepart: e.currentTarget.value,
                  }
                  setFilteringData(updatedFilteringData)
                }}
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              <Button
                className='d-flex align-items-center font-85 bg-remora-primary border-remora-primary'
                onClick={() => filterConnaissement(dataStore.access_token, filteringData)}
                disabled={isEmpty}
              >
                {isLoading ? 
              <Spinner size="sm" animation="border" role="status" className='me-2'/>
              : 
              <i className='ri-search-line me-0  me-md-2'></i>
            }
                <span className='d-none d-md-block'>Rechercher</span>{' '}
              </Button>
              {isFiltering && (
                <span
                  className='pointer'
                  onClick={() => {
                    setFilteringData({
                      numeroConnaissement: '',
                      expediteur_denomination: '',
                      destinataire: '',
                      idNavire: '',
                      evenementConnaissement: '',
                      dateDepart: '',
                      nomIleArrivee: '',
                      dateArrivee: '',
                    })
                  }}
                >
                  <i className='ri-close-line fs-5'></i>
                  <u>Réinitialiser</u>
                </span>
              )}
            </th>
          </tr>
        </thead>
        </React.Fragment>
      )
    }
    