import React from 'react'
import { Dropdown } from 'react-bootstrap'
import userStore, { UserState } from '../../stores/userStore'

interface menuDropType {
  isTransporter: boolean
  isDarkMode: boolean
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>
}

export default function MenuDrop({ menuDropProps }: { menuDropProps: menuDropType }) {
  const { isTransporter, isDarkMode, setIsDarkMode } = menuDropProps
  const authLogout = userStore((state: UserState) => state.authLogout)
  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          variant='transparent'
          id='dropdown-basic'
          className='border-0 no-chevron'
        >
          <i className='ri-more-2-line fs-4'></i>
        </Dropdown.Toggle>
        <Dropdown.Menu align='end'>
          {isTransporter && (
            <div className='px-3'>
              <div className='responsive-font-small text-center border border-secondary rounded-pill px-3'>
                LOGIPOL
              </div>
            </div>
          )}
          <div className='px-3'>
            <i className='ri-vuejs-fill fs-5'></i> 1.0.1
          </div>
          <Dropdown.Divider />
          {!isTransporter && (
            <>
              <Dropdown.Item href='/compagnies' className='d-block d-md-none'>
                <i className='ri-community-line fs-5'></i> Compagnies
              </Dropdown.Item>
              <Dropdown.Item href='/utilisateurs' className='d-block d-md-none'>
                <i className='ri-user-line fs-5'></i> utilisateurs
              </Dropdown.Item>
            </>
          )}
          <Dropdown.Item className='text-secondary' onClick={() => setIsDarkMode(!isDarkMode)}>
            <i className={`ri-${!isDarkMode ? 'moon' : 'sun'}-line fs-4`}></i> Thème
          </Dropdown.Item>
          <Dropdown.Item href='/connexion' onClick={authLogout}>
            <i className='ri-logout-box-r-line fs-5'></i> Déconnexion
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
