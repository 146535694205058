import React from 'react'

export default function OrderTableTitle() {
  return (
    <thead className=''>
    <tr>
      <th className='p-1 p-sm-2'></th>

      <th className='responsive-font-small p-1 p-sm-2'>Date</th>
      <th className='responsive-font-small p-1 p-sm-2'>Numéro Facture</th>
      <th style={{ width: '265px' }} className='responsive-font-small p-1 p-sm-2'>
        Client
      </th>
      <th className='responsive-font-small p-1 p-sm-2'>N°voyage</th>
      <th className='responsive-font-small p-1 p-sm-2'>Statut</th>
      <th className='responsive-font-small p-1 p-sm-2'>Navire</th>
      <th className='responsive-font-small p-1 p-sm-2'>Arrivée</th>
      <th className='responsive-font-small p-1 p-sm-2'></th>
    </tr>
  </thead>
  )
}
