import React from "react"
import { errorType } from "../../definitions/errorType"
import ConnaissementServices from "../../services/connaissements/ConnaissementServices"
import IlesService from "../../services/IlesService"
import NaviresService from "../../services/navires/NaviresService"
import TrajetsService from "../../services/TrajetsService"
import userStore, { UserState } from "../../stores/userStore"


//get Navire list
export const _getShipments = async (
  token: string,
  dataStore: any,
  setNaviresData: React.Dispatch<
    React.SetStateAction<{
      id: number
      name: string
    }[]>
  >
) => {
  try {
    const response = await NaviresService.getNaviresList(token)
    const navires = response.data?.map((nav: any) => {
      return {
        id: nav.id,
        name: nav.nom,
      }
    })
    setNaviresData(navires)
  } catch (error: any) {
    console.log(error)
    if (error?.response?.data?.error === 'invalid_token') {
      const idCompany = dataStore?.company && dataStore?.company[0]?.id_company
      _refreshToken(dataStore?.token, idCompany)
    }
  }
}



export const _getIslandByName = async (name: string, setListIsland: any) => {
    try {
      if(name?.length > 1){
      const response = await IlesService.getIslandByName(name)
      if (response?.data?.length > 0) {
        setListIsland(response.data)
      }
    }
    } catch (error) {
      console.log(error)
    }
  }
export const _getAIslandByName = async (name: string, setIsland: any) => {
    try {
      if(name?.length > 1){
      const response = await IlesService.getIslandByName(name)
      if (response?.data?.length > 0) {
        setIsland(response.data?.[0]?.nom)
      }
    }
    } catch (error) {
      console.log(error)
    }
  }

export  const __getTrajetByIslandId = async (
    idIle: string | null,
    page: number,
    limit: number,
    dateDebut: string,
    dateFin: string,
    nameNavire: string,
    setTrajetData: any,
    setSearchIslandName: any
  ) => {
    try {
      const response = await TrajetsService.getTrajetByIslandId(
        idIle,
        page,
        limit,
        dateDebut,
        dateFin
      )
      const filteredData = response.data?.content.filter(
        (navire: any) => navire.nomNavire === nameNavire
      )
      console.log(response.data)
      if (filteredData?.length > 0) {
        setTrajetData(filteredData)
        setSearchIslandName('')
      } else {
        setTrajetData(response.data?.content)
        setSearchIslandName('')
      }
    } catch (error) {
      console.log(error)
    }
  }

export const _getPlanningOfNavire = async (
    idNavire: string | null,
    page: string,
    limit: string,
    dateDebut: string,
    dateFin: string,
    setSelectedTrajet: any,
    setTrajetData: any,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setIsError: React.Dispatch<React.SetStateAction<errorType>>
  ) => {
    setIsLoading(true)
    setIsError({
      error: false,
      message: "",
    })
    try {
      const response = await TrajetsService.getTrajetByNavireId(
        idNavire,
        page,
        limit,
        dateDebut,
        dateFin
      )

      if(response.data.content?.length === 0 ){
        setIsLoading(false)
        setIsError({
          error: true,
          message: "Aucun trajet trouvé",
        })
        setSelectedTrajet(undefined)
        setTrajetData(undefined)

      }
      else if (response.data.content.length > 0) {
        setSelectedTrajet(undefined)
        setTrajetData(response.data.content)
        setIsLoading(false)

      }
    } catch (error: any) {
      console.log(error)
    setIsLoading(false)
    setIsError({
      error: true,
      message: error?.response?.data?.message,
    })

    }
  }

export const _getPlanningMooz = async (
  idNavire: number,
  page: string,
  limit: string,
  dateDebut: string,
  dateFin: string,
  setSelectedTrajet: any,
  setTrajetData: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsError: React.Dispatch<React.SetStateAction<errorType>>,
  ileArrivee: string,
) => {
  setIsLoading(true)
  setIsError({
    error: false,
    message: "",
  })
  try {
    const responseIsland = await IlesService.getIslandByName(ileArrivee)

    const response = await TrajetsService.getPlanningMoorea(idNavire, dateDebut, dateFin, 29)
    
    if (response.data?.length === 0) {
      setIsError({
        error: true,
        message: "Aucun trajet trouvé",
      })
      setSelectedTrajet(undefined)
      setTrajetData(undefined)
      setIsLoading(false)
    } else if (response.data.length > 0) {
      const arrivee = response.data?.filter((trajet: any) => trajet?.destinationArrivee?.toLowerCase().includes(ileArrivee.toLowerCase()))
  
      
      if (responseIsland?.data?.length === 0) {
        setTrajetData(response.data.content)
      } else {
        if (arrivee?.length === 0) {
          setIsLoading(false)
          setIsError({
            error: true,
            message: 'Ce navire ne touche pas votre île de destination.',
          })
          setTrajetData(undefined)
        } else {
          setSelectedTrajet(undefined)
          setTrajetData(response.data)

        }}

      setIsLoading(false)
    }
  } catch (error: any) {
    console.log(error)
    setIsLoading(false)
    const errorMessage = error?.response?.data?.message === "Erreur d'accès aux données" ? "Vérifiez les dates de début et de fin de la période" : error?.response?.data?.message
    setIsError({
      error: true,
      message: errorMessage,
    })
  }
}

export  const _getTrajetByIslandId = async (
  idIle: string | null,
  page: number,
  limit: number,
  dateDebut: string,
  dateFin: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsError: React.Dispatch<React.SetStateAction<errorType>>,
  setTrajetIslandData: any,
  initIleArrivee: string,
  searchIleArrivee: string,
) => {
  setIsLoading(true)
  setIsError({
    error: false,
    message: "",
  })
  try {
    let responseIsland: any = undefined
    if (initIleArrivee !== '') {
      responseIsland = await IlesService.getIslandByName(initIleArrivee)
    }
    const responseSearchIsland =  await IlesService.getIslandByName(searchIleArrivee)
   
    
    setIsLoading(false)
    

      if (responseSearchIsland?.data?.length === 0) {
        setIsError({
          error: true,
          message: "L'île choisie n'existe pas",
        })
        setIsLoading(false)
        setTrajetIslandData(undefined)
      }else if (responseIsland?.data?.[0]?.id !== responseSearchIsland?.data?.[0]?.id && responseIsland?.data?.[0]?.id !== undefined) {
        setIsError({
          error: true,
          message: "Attention l'île choisie n'est pas la même que celle du destinataire",	
        })
        setIsLoading(false)
        setTrajetIslandData(undefined)

      }else {
        const response = await TrajetsService.getTrajetByIslandId(
          responseSearchIsland?.data?.[0]?.id,
          page,
          limit,
          dateDebut,
          dateFin
        )
        if (response.data.content?.length === 0) {
          setIsError({
            error: true,
            message: "Aucun trajet trouvé, vérifiez les dates de début et de fin de la période",
          })
          setTrajetIslandData(undefined)
          setIsLoading(false)
        }else{
          setIsLoading(false)
          setTrajetIslandData(response.data?.content)
          setIsError({
            error: false,
            message: "",
          })
        }
  
      }
    // }

  
    
    setIsLoading(false)
    

      if (responseSearchIsland?.data?.length === 0) {
        setIsError({
          error: true,
          message: "L'île choisie n'existe pas",
        })
        setIsLoading(false)
        setTrajetIslandData(undefined)
      }else if (responseIsland?.data?.[0]?.id !== responseSearchIsland?.data?.[0]?.id && responseIsland?.data?.[0]?.id !== undefined) {
        setIsError({
          error: true,
          message: "Attention l'île choisie n'est pas la même que celle du destinataire",	
        })
        setIsLoading(false)
        setTrajetIslandData(undefined)

      }else {
        const response = await TrajetsService.getTrajetByIslandId(
          responseSearchIsland?.data?.[0]?.id,
          page,
          limit,
          dateDebut,
          dateFin
        )
        if (response.data.content?.length === 0) {
          setIsError({
            error: true,
            message: "Aucun trajet trouvé, vérifiez les dates de début et de fin de la période",
          })
          setTrajetIslandData(undefined)
          setIsLoading(false)
        }else{
          setIsLoading(false)
          setTrajetIslandData(response.data?.content)
          setIsError({
            error: false,
            message: "",
          })
        }
  
      }
    // }

  } catch (error: any) {
    console.log(error)
    setIsLoading(false)
    setIsError({
      error: true,
      message: error?.response?.data?.message,
    })
  }
}



export const _refreshToken = async (token: string | null, id: number) => {
  try {
    const response = await ConnaissementServices.getrefreshToken(token, id)
    // console.log(response?.data.access_token)
    userStore
      .getState()
      .authLogin(
        null,
        null,
        null,
        null,
        token,
        null,
        null,
        null,
        null,
        response?.data?.access_token
      )
  } catch (error) {
    console.log(error)
  }
}


///Connaissement

export const _connaissementDataTable = async (setIsLoading: any, setConnaissementData: any, currentPage: number, limit: number) => {
  const dataStore =  userStore((state: UserState) => state)
  setIsLoading(true)
  try {
    const response = await ConnaissementServices.getConnaissement(dataStore?.access_token, currentPage, limit)
    console.log(response.data)
    setConnaissementData(response?.data?.content)
    setIsLoading(false)
  } catch (error: any) {
    console.log(error)
    setIsLoading(false)
    if (error?.response?.data?.error === 'invalid_token') {
      const idCompany = dataStore?.company && dataStore?.company[0]?.id_company
      _refreshToken(dataStore?.token, idCompany)
    }
  }
}

export const _filterConnaissement = async (token: any,  filteringData: any, setIsLoading: any, setTotalPages: any,  setConnaissementData: any, setIsFiltering: any, limit: number) => {

  // Filtrer les paramètres qui ne sont pas définis ou sont vides
  const filteredParams = Object.entries(filteringData)
  .filter(([key, value]) => value) // Garde les paires où la valeur est définie (non null, non undefined, non vide)
  .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`) // Encode chaque paramètre
  .join('&'); // Les concatène avec '&'

  setIsLoading(true)


 try{

   const responseFilteredConnaissement = await ConnaissementServices.getFilteredConnaissement(token, 0, filteredParams, limit)
   const sortedData = responseFilteredConnaissement?.data?.content?.sort((a: any, b: any) => b.id - a.id)
   setTotalPages(responseFilteredConnaissement?.data?.totalPages)
   setConnaissementData(sortedData)
   setIsLoading(false)
   setIsFiltering(true)
 }catch(error){
   setIsLoading(false)
   console.log(error)
   setIsFiltering(true)
 }
}