import React from 'react'
import {
  Alert,
  Container,
  Form,
  Image,
  InputGroup,
  Spinner,
  Table,
} from 'react-bootstrap'
import { DetailConnaissementModal } from '../../component/ui/Modal/Modals'
import ConnaissementService from '../../services/connaissements/ConnaissementServices'
import userStore from '../../stores/userStore'
import { errorType } from '../../definitions/errorType'
import { ToastDeleteSuccess, ToastError, ToastInfo, ToastUpdateUserSuccess } from '../../component/ui/Toast/Toastes'
import {
  QrcodeConnaissementModal,
} from '../../component/ui/Modal/ConnaissementModals'
import { _refreshToken } from '../../utils/api/apiControlerFunctions'
import { useReactToPrint } from 'react-to-print'
import noResult from '../../styles/images/no_result.png'
import BillOfTableTitle from '../../component/billOfLading/BillOfTableTitle'
import TransporterTable from '../../component/ui/TransporterTable'
import TransporterFilterTab from '../../component/transporter/TransporterFilterTab'


export default function TransporterDemande() {
    const dataStore = userStore((state: any) => state)

    const isTransporter : boolean = dataStore?.roles && dataStore?.roles[0]?.name === 'transporteur'
  
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [isErrorConnaiss, setIsErrorConnaiss] = React.useState<errorType>({
      error: false,
      message: '',
    })
    const [isError, setIsError] = React.useState<errorType>({
      error: false,
      message: '',
    })
    const [infoOrder, setInfoOrder] = React.useState<string>("")
    const [isFiltering, setIsFiltering] = React.useState<boolean>(false)
  
    const [connaissementData, setConnaissementData] = React.useState<any>([])
    const [tokenTab, setTokenTab] = React.useState<any>([])
    const [selectedConnaissement, setSelectedConnaissement] = React.useState<any>({})
  
    const [filteringData, setFilteringData] = React.useState<any>({
      numeroConnaissement: '',
      expediteur_denomination: '',
      destinataire: '',
      idNavire: '',
      evenementConnaissement: '',
      dateDepart: '',
      nomIleArrivee: '',
      dateArrivee: '',
    })
  
    const [currentPage, setCurrentPage] = React.useState<number>(0)
    const [totalPages, setTotalPages] = React.useState<number>(0)
    const [itemPerPage, setItemPerPage] = React.useState<number>(40)
  
    const isEmpty =
      filteringData?.numeroConnaissement === '' &&
      filteringData?.expediteur_denomination === '' &&
      filteringData?.destinataire === '' &&
      filteringData?.idNavire === '' &&
      filteringData?.evenementConnaissement === '' &&
      filteringData?.dateDepart === '' &&
      filteringData?.nomIleArrivee === '' &&
      filteringData?.dateArrivee === ''
  
     
  
    //////////////////
    //Toastes
    /////////////////
    //Toast Delete success
    const [showDeleteSuccess, setShowDeleteSuccess] = React.useState<boolean>(false)
    const toggleShowDeleteSuccess = () => setShowDeleteSuccess(!showDeleteSuccess)
  
    const [showUpdateSuccess, setShowUpdateSuccess] = React.useState<boolean>(false)
    const toggleShowUpdateSuccess = () => setShowUpdateSuccess(!showUpdateSuccess)
  
    const [showOrderError, setShowOrderError] = React.useState<boolean>(false)
    const toggleShowOrderError = () => setShowOrderError(!showOrderError)
  
    const [showInfo, setShowInfo] = React.useState<boolean>(false)
    const toggleShowInfo = () => setShowInfo(!showInfo)
  
    //////////////////
    //Modals
    /////////////////
    //detail connaissement
    const [show, setShow] = React.useState(false)
    const handleClose = () => {
      setShow(false)
    }
    const handleShow = () => setShow(true)
  
  // Show qrcode
    const [showQrcode, setShowQrcode] = React.useState(false)
    const handleCloseQrcode = () => {
      setShowQrcode(false)
    }
    const handleShowQrcode = () => setShowQrcode(true)
  
   
    
    const printRef = React.useRef(null)
  
    // Utiliser useReactToPrint pour gérer l'impression
    const handlePrint = useReactToPrint({
      content: () => printRef.current,
      documentTitle: `${selectedConnaissement?.numero}`, // Titre du document imprimé
    })
  
    React.useEffect(() => {
      const transportTokens = dataStore?.company?.map((comp: any) => {
        return (
          comp.access_token
        )
      })
      setTokenTab(transportTokens)
    }, [])
    
    React.useEffect(() => {
      connaissementDataTable(currentPage, setTotalPages, itemPerPage)
    }, [tokenTab])
    
    React.useEffect(() => {
      if (isEmpty) {
        connaissementDataTable(currentPage, setTotalPages, itemPerPage)
        setIsFiltering(false)
      }
    }, [filteringData])
  
    React.useEffect(() => {
      connaissementDataTable(currentPage, setTotalPages, itemPerPage)
    }, [currentPage, itemPerPage])
  
    
  
    const filterConnaissement = async (token: any,  filteringData: any) => {
  
       // Filtrer les paramètres qui ne sont pas définis ou sont vides
       const filteredParams = Object.entries(filteringData)
       .filter(([key, value]) => value) // Garde les paires où la valeur est définie (non null, non undefined, non vide)
       .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`) // Encode chaque paramètre
       .join('&'); // Les concatène avec '&'
  
       setIsLoading(true)
  
  
      try{
  
        const responseFilteredConnaissement = await ConnaissementService.getFilteredTransporterConnaissement(token, 0, filteredParams)
        const sortedData = responseFilteredConnaissement?.data?.content?.sort((a: any, b: any) => b.id - a.id)
        setTotalPages(responseFilteredConnaissement?.data?.totalPages)
        setConnaissementData(sortedData)
        setIsLoading(false)
        setIsFiltering(true)
      }catch(error){
        setIsLoading(false)
        console.log(error)
        setIsFiltering(true)
      }
    }
  
    const handleFilterConnaissement = (event: any) => {
      const value = event?.target?.value
      if (value?.length > 2) {
        const filteredData = connaissementData.filter((item: any) => {
          return (
            item?.destinataire?.denomination?.toLowerCase().includes(value.toLowerCase()) ||
            item?.numero?.toLowerCase().includes(value.toLowerCase()) ||
            item?.expediteur?.denomination?.toLowerCase().includes(value.toLowerCase()) ||
            item?.dernierEtat?.evenementConnaissement
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            item?.ileArrivee?.nom?.toLowerCase().includes(value.toLowerCase()) ||
            item?.voyage?.dateDepart?.toLowerCase().includes(value.toLowerCase()) ||
            item?.voyage?.nomNavire?.toLowerCase().includes(value.toLowerCase()) ||
            item?.voyage?.periple[0]?.dateArrivee?.toLowerCase().includes(value.toLowerCase())
          )
        })
  
        setConnaissementData(filteredData)
      }
      if (value.length === 0) {
        connaissementDataTable(0, setTotalPages, itemPerPage)
      }
    }
  
    const connaissementDataTable = async (currentPage: number, setTotalPages: any, itemPerPage: number) => {
      setIsLoading(true)
      let newSortData: any[] = []
      try {

        const connaissementPromises = tokenTab?.map((token: string) =>
          ConnaissementService.getConnaissement(
            token,
            currentPage,
            itemPerPage,
          ).then((response: any) => {
            newSortData = [...newSortData,...response?.data?.content]
           

          })
          .catch((error: any) => {
            if (error) {
              console.log(error)
              setIsError({
                error: true,
                message: error?.response?.data?.message,
              })
            }
          })
        )

         await Promise.all([...(connaissementPromises || [])])
         
        const sortedData = newSortData?.sort((a: any, b: any) => b.id - a.id)
        setTotalPages(1)
        setConnaissementData(sortedData)
        setIsLoading(false)
        setIsErrorConnaiss({
          error: false,
          message: '',
        })
      } catch (error: any) {
        console.log(error)
        setIsError({
          error: true,
          message: error?.response?.data?.error ? error?.response?.data?.error :  error?.message === "Network Error" ? "Oups, une erreur du côté de Revatua est survenue. Veuillez réessayer plus tard." : error?.message
        })
        setIsLoading(false)
  
        if (error?.response?.data?.error === 'invalid_token') {
          setIsError({
            error: true,
            message: error?.response?.data?.error
          })
          const idCompany = dataStore?.company && dataStore?.company[0]?.id_company
          _refreshToken(dataStore?.token, idCompany)
        }
      }
    }
  
   
  
  
    
  
    const handlePageChange = (pageNumber: number) => {
      setCurrentPage(pageNumber)
    }
  
    const billOfilterProps = { filteringData, setFilteringData, isTransporter, filterConnaissement, isFiltering, isEmpty, isLoading}
  
  
  //Modals props
    const detailOrderModalProps = {
      show,
      selectedConnaissement,
      handleClose,
      handlePrint,
      printRef,
    }
    const qrCodeModalProps = { showQrcode, selectedConnaissement, handleCloseQrcode }
  
    const toastUpdateUserSuccessProps = { showUpdateSuccess, toggleShowUpdateSuccess }
    const toastDeleteSuccessProps = { showDeleteSuccess, toggleShowDeleteSuccess }
    const toastErrorProps = {showOrderError, toggleShowOrderError, isError}; 
    const toastInfoProps = {showInfo, toggleShowInfo, infoOrder}; 
  

    return (
        <div className='p-1 p-sm-3'>
          <div className='rounded-4 py-3 mb-2 border border-4  border-info'>
          <h3 className='text-secondary text-center mb-0 '>DEMANDES</h3>
          </div>
          <div>
            <Form.Group className='mb-3' controlId='searchBar'>
              <InputGroup className=''>
                <InputGroup.Text id='basic-addon1' className='bg-secondary border'>
                  <i className='ri-search-line text-light'></i>
                </InputGroup.Text>
                <Form.Control
                  className='border'
                  type='text'
                  autoComplete='on'
                  placeholder='Recherche'
                  onChange={handleFilterConnaissement}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <Table striped hover responsive className='transport-table responsive-font-small border w-100'>
            <BillOfTableTitle isTransporter={isTransporter} />
            <TransporterFilterTab billOfilterProps={billOfilterProps} />
            <tbody>
              {!isLoading &&
                connaissementData?.length > 0 &&
                connaissementData?.map((connaissement: any, indx: number) => {
                  const isDangerous =  connaissement.detailConnaissements.some((detail: any) => detail.matiereDangereuse === true)
                  const isFragile =  connaissement.detailConnaissements.some((detail: any) => detail.fragile === true)
                  
                  return (
                    <React.Fragment key={indx}>
                      {(connaissement?.dernierEtat?.evenementConnaissement ===
                        'DEMANDE' 
                        // || connaissement?.dernierEtat?.evenementConnaissement ===
                        // 'DEMANDE'
                      ) && (
                        <TransporterTable
                          setSelectedConnaissement={setSelectedConnaissement}
                          connaissement={connaissement}
                          handleShow={handleShow}
                          handleShowQrcode={handleShowQrcode}
                        />
                      )}
                    </React.Fragment>
                  )
                })}
    
              {!isLoading && !isError.error && isFiltering && connaissementData?.length === 0 && (
                <tr className=''>
                  <td></td>
                  <td colSpan={7} className='text-center'>
                    <Image src={noResult} height={32} /> Votre recherche n'a donné aucun résultat
                  </td>
                  <td></td>
                </tr>
              )}
    
              {!isLoading && isErrorConnaiss.error && (
                <tr className=''>
                  <td colSpan={isTransporter ? 10 : 9} className='text-center'>
                    <Alert variant='danger'>
                      {isErrorConnaiss?.message}
                    </Alert>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className='d-flex align-items-center'>
            {/* <PaginationZero
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
            <ItemsLimiter itemPerPage={itemPerPage} setItemPerPage={setItemPerPage} /> */}
          </div>
    
          {isLoading && (
            <Container
              fluid
              className='bg-body-tertiary d-flex justify-content-center align-items-center vh-100 text-center'
            >
              <Spinner variant='primary' /> <span className='ms-3'>Loading...</span>
            </Container>
          )}
    
          <DetailConnaissementModal detailOrderModalProps={detailOrderModalProps} />
          <QrcodeConnaissementModal qrCodeModalProps={qrCodeModalProps} />
          {/* <UpdateToDemandeModal updateToDemandeModalProps={updateToDemandeModalProps} />
          <UpdateMultiToDemandeModal
            updateMultiToDemandeModalProps={updateMultiToDemandeModalProps}
          /> */}
          <ToastUpdateUserSuccess toastUpdateUserSuccessProps={toastUpdateUserSuccessProps} />
          <ToastDeleteSuccess toastDeleteSuccessProps={toastDeleteSuccessProps} />
          <ToastError toastErrorProps={toastErrorProps} />
    
          <ToastInfo toastInfoProps={toastInfoProps} />
    
        </div>
      )
}
