import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import userStore, { UserState } from '../../../stores/userStore'
import ConnaissementServices from '../../../services/connaissements/ConnaissementServices'

export default function FooterDetail({ footerDetailProps }: any) {
  const { selectedConnaissement, toPDF, setPdfData, handlePrint, handleClose } =
    footerDetailProps
  const dataStore = userStore((state: UserState) => state)
  const isTransporter = dataStore?.roles && dataStore.roles[0]?.name === 'transporteur'
  const numPdt = selectedConnaissement?.numero
    ? selectedConnaissement?.numero
    : selectedConnaissement?.id

  const getPDFConnaissementByEvenement = (
    token: string,
    id: number,
    idEven: number,
    num: string,
    setPdfData: any
  ) => {
    try {
      const response = ConnaissementServices.getPDFConnaissement(
        token,
        id,
        idEven,
        num,
        setPdfData
      )
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal.Footer className='sticky-bottom border-0'>
      {!isTransporter && (
        <React.Fragment>
          <Button className='print-button' variant='success' onClick={() => handlePrint()}>
            <i className='ri-printer-fill'></i> Imprimer
          </Button>
          <Button
            className='print-button'
            variant='warning'
            onClick={() => {
              if (
                selectedConnaissement?.dernierEtat?.evenementConnaissement === 'BROUILLON' ||
                selectedConnaissement?.dernierEtat?.evenementConnaissement === 'DEMANDE'
              ) {
                toPDF()
              } else {
                getPDFConnaissementByEvenement(
                  dataStore.access_token,
                  selectedConnaissement?.id,
                  selectedConnaissement?.dernierEtat?.id,
                  numPdt,
                  setPdfData
                )
              }
            }}
          >
            <i className='ri-download-2-fill'></i> Télécharger
          </Button>
        </React.Fragment>
      )}

      <Button className='print-button' variant='secondary' onClick={handleClose}>
        <i className='ri-close-cirlce-fill'></i> Fermer
      </Button>
    </Modal.Footer>
  )
}
