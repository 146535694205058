import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { PieChart } from '@rsuite/charts'
import data from '../../data/charts/donut.json'

export default function DashBoard() {
  const { billData } = useOutletContext<any>()
  const colors: any = ['#34c3ff', '#1464ac']
  const chartData: any = data

  const [donutData, setDonutData] = React.useState<any>([])
  const status = [
    'BROUILLON',
    'DEAMANDE',
    'DEMANDE_REFUSEE',
    'SAISIE',
    'OFFICIALISE',
    'OFFICIALISE_SOUS_RESERVE',
    'MODIFIE',
    'PRIS_EN_CHARGE',
    'EMBARQUE',
    'EMBARQUEMENT_REFUSE',
    'TRANSFERE',
    'ANNULE',
  ]
  //https://rsuitejs.com/resources/extensions/

  React.useEffect(() => {
    statusChartData()
  }, [billData])

  console.log(donutData)
  const statusChartData = () => {
    // const brouillon = billData?.filter(
    //   (item: any) => item?.dernierEtat.evenementConnaissement === "BROUILLON"
    // )
    // const demande = billData?.filter(
    //   (item: any) => item?.dernierEtat.evenementConnaissement === "DEMANDE"
    // )

    // setDonutData([["BROUILLON", brouillon?.length], ["DEMANDE", demande?.length]])
    const donutData = status.map((etat) => {
      const filteredData = billData?.filter(
        (item: any) => item?.dernierEtat.evenementConnaissement === etat
      )
   
        return [etat, filteredData?.length || 0];
      
    }).filter(([, count]) => count > 0);
    setDonutData(donutData);
  }

  // case 'BROUILLON': return 'yellow';

  // case 'DEMANDE': return 'cyan';

  // case 'DEMANDE_REFUSEE': return 'red';

  // case 'SAISIE': return 'blue';

  // case 'OFFICIALISE': return 'violet';

  // case 'OFFICIALISE_SOUS_RESERVE': return 'orange';

  // case 'MODIFIE': return 'yellow';

  // case 'PRIS_EN_CHARGE': return 'orange';

  // case 'EMBARQUE': return 'green';

  // case 'EMBARQUEMENT_REFUSE': return 'red';

  // case 'TRANSFERE': return 'violet';

  // case 'ANNULE': return 'red';

  return (
    <div className='p-3'>
      <PieChart name='Donut' data={donutData} donut colorBy={colors} />
    </div>
  )
}
