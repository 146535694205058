import React from 'react';
import './App.css';
import { Col, Row } from 'react-bootstrap';
import {  Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './component/layout/Header';
import SideMenu from './component/layout/SideMenu';
import VersionService from './services/version/VersionService';
import userStore, { UserState } from './stores/userStore';
import { ToastUpdateUserSuccess } from './component/ui/Toast/Toastes';
import BottomNavBar from './component/layout/BottomNavBar';
import { _getShipments } from './utils/api/apiControlerFunctions';
import { UserType } from './definitions/UserType';
import { CompanyType } from './definitions/CompanyType';
import HeaderTransporter from './component/transporter/HeaderTransporter';

function App() {

  const dataStore = userStore((state: UserState) => state)
  const navigate = useNavigate()
  const location = useLocation()
  const isHomePage = location.pathname === '/'

  const isTransporter = dataStore?.roles && dataStore.roles[0]?.name ==='transporteur'


  const [headerData, setHeaderData] = React.useState<any>({
    title: "",
    borderColor: ""
  });
  const [billData, setBillData] = React.useState<any>();
  const [companiesData, setCompaniesData] = React.useState<CompanyType[]>();
  const [naviresData, setNaviresData] = React.useState<
    {
      id: number
      name: string
    }[]
  >([])
  const [userData, setUserData] = React.useState<UserType[]>();

  const [showUpdateSuccess, setShowUpdateSuccess] = React.useState<boolean>(false);
  const toggleShowUpdateSuccess = () => setShowUpdateSuccess(!showUpdateSuccess);



  React.useEffect(() => {
    getVersion()
    if (!dataStore.token || dataStore.token === undefined || dataStore.token === null || dataStore.token === "") {
      navigate('/connexion')
    }
    
    if(isTransporter){
      navigate('/connaissements-demande')
    }
    if(isHomePage){
      navigate('/factures')
    }
  }, [])

  React.useEffect(() => {
    if(dataStore.access_token && dataStore.access_token !== undefined && dataStore.access_token !== ""){
      _getShipments(dataStore.access_token, dataStore, setNaviresData)
    }
  }, [dataStore.access_token])

  const getVersion = async () => {
    try {
      await VersionService.getVersion()
    } catch (error) {
      console.log(error)
    }
  }

  const title = headerData.title
  const borderColor = headerData.borderColor

  const sideMenuProps = {}
  const toastUpdateUserSuccessProps = { showUpdateSuccess, toggleShowUpdateSuccess }
  const headerTrasnpoterProps = {title, borderColor}
  return (
    <div className='bg-body-tertiary pe-0'>
      {/* { !isTransporter ? ( */}

        <Header />
      {/* ) : (
        <HeaderTransporter headerTrasnpoterProps={headerTrasnpoterProps} />
       
      )
      } */}
      <div className=''>
        <Row className='gx-0'>
          <Col className='vh-100 d-none d-md-block' xs={2} sm={2} md={2} lg={2} xl={2}>
            <SideMenu {...sideMenuProps} />
          </Col>
          <Col xs={12} sm={12} md={10} lg={10} xl={10} className='ps-0 pe-0'>

            <Outlet
              context={{
                userData,
                setUserData,
                companiesData,
                setCompaniesData,
                setShowUpdateSuccess,
                naviresData,
                headerData,
                setHeaderData,
                billData, setBillData
              }}
            />
          </Col>
        </Row>
        <BottomNavBar />
      </div>
      <ToastUpdateUserSuccess toastUpdateUserSuccessProps={toastUpdateUserSuccessProps} />
    </div>
  )
}

export default App;
