import React from 'react'
import {
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap'
import { DetailConnaissementModal } from '../../component/ui/Modal/Modals'
import { Tag } from 'rsuite'
import { _colisTotal, _tagStatus, _thousandSeparator, _totalMontant, _totalWeight, _volumeTotal } from '../../utils/functions'
import ConnaissementService from '../../services/connaissements/ConnaissementServices'
import OrdersService from '../../services/orders/OrdersService'
import userStore from '../../stores/userStore'
import { errorType } from '../../definitions/errorType'
import { ToastDeleteSuccess, ToastError, ToastInfo, ToastUpdateUserSuccess } from '../../component/ui/Toast/Toastes'
import {
  DeleteModal,
  NbPaletteModal,
  QrcodeConnaissementModal,
  UpdateMultiToDemandeModal,
  UpdateToDemandeModal,
} from '../../component/ui/Modal/ConnaissementModals'
import { _refreshToken } from '../../utils/api/apiControlerFunctions'
import { useReactToPrint } from 'react-to-print'
import PaginationZero from '../../component/ui/PaginationZero'
import BillOfTableTitle from '../../component/billOfLading/BillOfTableTitle'
import BillOfFilter from '../../component/billOfLading/BillOfFilter'
import ItemsLimiter from '../../component/billOfLading/ItemsLimiter'
import ConnaissementServices from '../../services/connaissements/ConnaissementServices'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { QRCode } from 'antd'
import dpam from '../../styles/images/dpam.png'
import {
  WarningTwoTone
} from '@ant-design/icons';
import NoResult from '../../component/ui/error/NoResult'
import ErrorConnaissementMessage from '../../component/ui/error/ErrorConnaissementMessage'
import SearchBar from '../../component/billOfLading/SearchBar'
import { ResultConnaissementType } from '../../definitions/ConnaissementType'

export default function BillOfLading() {
  const { billData, setBillData } = useOutletContext<any>()
  const dataStore = userStore((state: any) => state)
  const navigate = useNavigate()

  console.log(billData)
  const isTransporter : boolean = dataStore?.roles && dataStore?.roles[0]?.name === 'transporteur'

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isErrorConnaiss, setIsErrorConnaiss] = React.useState<errorType>({
    error: false,
    message: '',
  })
  const [isError, setIsError] = React.useState<errorType>({
    error: false,
    message: '',
  })
  const [infoOrder, setInfoOrder] = React.useState<string>("")
  const [isFiltering, setIsFiltering] = React.useState<boolean>(false)

  const [connaissementData, setConnaissementData] = React.useState<any>([])
  const [selectedConnaissement, setSelectedConnaissement] = React.useState<ResultConnaissementType>()
  const [checkedMultiConnaissement, setCheckedMultiConnaissement] = React.useState<any>([])
  const [checkedConnaissement, setCheckedConnaissement] = React.useState<number[]>([])
  const [idsOrder, setIdsOrder] = React.useState<any>([])
  const [filteringData, setFilteringData] = React.useState<any>({
    numeroConnaissement: '',
    expediteur_denomination: '',
    destinataire_denomination: '',
    idNavire: '',
    evenementConnaissement: '',
    dateDepart: '',
    nomIleArrivee: '',
    dateArrivee: '',
  })
  const [nbPalette, setNbPalette] = React.useState<number>(0)

  const [currentPage, setCurrentPage] = React.useState<number>(0)
  const [totalPages, setTotalPages] = React.useState<number>(0)
  const [itemPerPage, setItemPerPage] = React.useState<number>(20)

  const isEmpty =
    filteringData?.numeroConnaissement === '' &&
    filteringData?.expediteur_denomination === '' &&
    filteringData?.destinataire === '' &&
    filteringData?.idNavire === '' &&
    filteringData?.evenementConnaissement === '' &&
    filteringData?.dateDepart === '' &&
    filteringData?.nomIleArrivee === '' &&
    filteringData?.dateArrivee === ''



  //////////////////
  //Toastes
  /////////////////
  //Toast Delete success
  const [showDeleteSuccess, setShowDeleteSuccess] = React.useState<boolean>(false)
  const toggleShowDeleteSuccess = () => setShowDeleteSuccess(!showDeleteSuccess)

  const [showUpdateSuccess, setShowUpdateSuccess] = React.useState<boolean>(false)
  const toggleShowUpdateSuccess = () => setShowUpdateSuccess(!showUpdateSuccess)

  const [showOrderError, setShowOrderError] = React.useState<boolean>(false)
  const toggleShowOrderError = () => setShowOrderError(!showOrderError)

  const [showInfo, setShowInfo] = React.useState<boolean>(false)
  const toggleShowInfo = () => setShowInfo(!showInfo)

  //////////////////
  //Modals
  /////////////////
  //detail connaissement
  const [show, setShow] = React.useState(false)
  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => setShow(true)

  //update palette count
  const [showUpdatePalette, setShowUpdatePalette] = React.useState(false)
  const handleCloseUpdatePalette = () => {
    setShowUpdatePalette(false)
  }
  const handleShowUpdatePalette = () => setShowUpdatePalette(true)

  //Show qrcode
  const [showQrcode, setShowQrcode] = React.useState(false)
  const handleCloseQrcode = () => {
    setShowQrcode(false)
  }
  const handleShowQrcode = () => setShowQrcode(true)

  //Update connaissement to demande
  const [showUpdateToDemandeModal, setShowUpdateToDemandeModal] = React.useState(false)
  const handleCloseUpdateToDemandeModal = () => {
    setIdsOrder([])
    setShowUpdateToDemandeModal(false)
  }
  const handleShowUpdateToDemandeModal = () => {
    setShowUpdateToDemandeModal(true)
  }

  //Update multi connaissement to demande
  const [showUpdateMultiToDemandeModal, setShowUpdateMultiToDemandeModal] =
    React.useState(false)
  const handleCloseUpdateMultiToDemandeModal = () => {
    setShowUpdateMultiToDemandeModal(false)
  }
  const handleShowUpdateMultiToDemandeModal = () => {
    getOrderSByIDBill()
    setShowUpdateMultiToDemandeModal(true)
  }

  //Delete connaissement
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  const handleCloseDeleteModal = () => {
    setIdsOrder([])
    setShowDeleteModal(false)
  }
  const handleShowDeleteModal = () => {
  
    setShowDeleteModal(true)}

  
  const printRef = React.useRef(null)
  const printref = React.useRef(null)

  // Utiliser useReactToPrint pour gérer l'impression
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${selectedConnaissement?.numero}`, // Titre du document imprimé
  })
  const handlePrintMulti = useReactToPrint({
    content: () => printref.current,
    documentTitle: `${selectedConnaissement?.numero}`, // Titre du document imprimé
  })

  React.useEffect(() => {
    connaissementDataTable(currentPage, setTotalPages, itemPerPage)
    if(isTransporter){
      navigate('/connaissements-demande')
    }
  }, [])

  React.useEffect(() => {
    if (isEmpty) {
      connaissementDataTable(currentPage, setTotalPages, itemPerPage)
      setIsFiltering(false)
    }
  }, [filteringData])

  React.useEffect(() => {
    connaissementDataTable(currentPage, setTotalPages, itemPerPage)
  }, [currentPage, itemPerPage])

  const getOrderSByIDBill = () => {
    setIsError({
      error: false,
      message: '',
    })
    try {
      const orderPromises = checkedConnaissement?.map((id: number) => {
      return  OrdersService.getOrdersByIdConnaissement(dataStore.token, id)
          .then((response: any) => {
            const checkedOrder = response?.data?.data?.map((order: any) => {
            return  idsOrder.push(order?.id)
            })
            console.log(checkedOrder)
          })
          .catch((error: any) => {
            setIsError({
              error: true,
              message: error?.response?.data?.message,
            })
            toggleShowOrderError()
          })
      })

      console.log(orderPromises)
    } catch (error) {
      console.log(error)
    }
  }

  const filterConnaissement = async (token: any,  filteringData: any) => {
    let filteredParams
     // Filtrer les paramètres qui ne sont pas définis ou sont vides
     if (
       filteringData.evenementConnaissement === 'OFFICIALISE' ||
       filteringData.evenementConnaissement === 'EMBARQUEMENT_REFUSE' ||
       filteringData.evenementConnaissement === 'PRIS_EN_CHARGE' ||
       filteringData.evenementConnaissement === 'EMBARQUE' ||
       filteringData.evenementConnaissement === 'MODIFIE' ||
       filteringData.evenementConnaissement === 'TRANSFERE' ||
       filteringData.evenementConnaissement === 'SAISIE'
     ) {
       const newData = {
         ...filteringData,
         evenementConnaissement: 'OFFICIALISE',
       }

       filteredParams = Object.entries(newData)
         .filter(([key, value]) => value) // Garde les paires où la valeur est définie (non null, non undefined, non vide)
         .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`) // Encode chaque paramètre
         .join('&') // Les concatène avec '&'
     } else {
       filteredParams = Object.entries(filteringData)
         .filter(([key, value]) => value) // Garde les paires où la valeur est définie (non null, non undefined, non vide)
         .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`) // Encode chaque paramètre
         .join('&') // Les concatène avec '&'
     }

     setIsLoading(true)


    try{

      const responseFilteredConnaissement = await ConnaissementService.getFilteredConnaissement(token, 1, filteredParams, itemPerPage)

  let sortedData: any = []
  if (
    filteringData.evenementConnaissement === 'OFFICIALISE' ||
    filteringData.evenementConnaissement === 'EMBARQUEMENT_REFUSE' ||
    filteringData.evenementConnaissement === 'PRIS_EN_CHARGE' ||
    filteringData.evenementConnaissement === 'EMBARQUE' ||
    filteringData.evenementConnaissement === 'MODIFIE' ||
    filteringData.evenementConnaissement === 'TRANSFERE' ||
    filteringData.evenementConnaissement === 'SAISIE'
  ) {
    const officialiseData = responseFilteredConnaissement?.data?.content?.filter(
      (data: any) =>
        data.dernierEtat?.evenementConnaissement === filteringData.evenementConnaissement
    )
    sortedData = officialiseData?.sort((a: any, b: any) => b.id - a.id)
  } else {
    sortedData = responseFilteredConnaissement?.data?.content?.sort(
      (a: any, b: any) => b.id - a.id
    )
  }
  console.log(sortedData)
      setTotalPages(responseFilteredConnaissement?.data?.totalPages)
      setConnaissementData(sortedData)
      setIsLoading(false)
      setIsFiltering(true)
    }catch(error){
      setIsLoading(false)
      console.log(error)
      setIsFiltering(true)
    }
  }

  const handleFilterConnaissement = (event: any) => {
    const value = event?.target?.value
    if (value?.length > 2) {
      const filteredData = connaissementData.filter((item: any) => {
        return (
          item?.destinataire?.denomination?.toLowerCase().includes(value.toLowerCase()) ||
          item?.numero?.toLowerCase().includes(value.toLowerCase()) ||
          item?.dernierEtat?.evenementConnaissement
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          item?.ileArrivee?.nom?.toLowerCase().includes(value.toLowerCase()) ||
          item?.voyage?.dateDepart?.toLowerCase().includes(value.toLowerCase()) ||
          item?.voyage?.nomNavire?.toLowerCase().includes(value.toLowerCase()) ||
          item?.voyage?.periple[0]?.dateArrivee?.toLowerCase().includes(value.toLowerCase())
        )
      })

      setConnaissementData(filteredData)
    }
    if (value.length === 0) {
      connaissementDataTable(0, setTotalPages, itemPerPage)
    }
  }

  const connaissementDataTable = async (currentPage: number, setTotalPages: any, itemPerPage: number) => {
    setIsLoading(true)
    try {
      const response = await ConnaissementService.getConnaissement(
        dataStore.access_token,
        currentPage,
        itemPerPage
      )
      if (response?.status === 200) {
        setIsErrorConnaiss({
          error: false,
          message: '',
        })
      }
      const sortedData = response?.data?.content?.sort((a: any, b: any) => b.id - a.id)
      // console.log(response?.data)
      setTotalPages(response?.data?.totalPages)
      setConnaissementData(sortedData)
      setBillData(sortedData)
      setIsLoading(false)
    } catch (error: any) {
      console.log(error)
      setIsError({
        error: true,
        message: error?.response?.data?.error ? error?.response?.data?.error :  error?.message === "Network Error" ? "Une erreur est survenue. Veuillez réessayer plus tard." : error?.message
      })
      setIsLoading(false)

      if (error?.response?.data?.error === 'invalid_token') {
        setIsError({
          error: true,
          message: error?.response?.data?.error
        })
        const idCompany = dataStore?.company && dataStore?.company[0]?.id_company
        _refreshToken(dataStore?.token, idCompany)
      }
    }
  }

  const updateBrouillonConnaissement = async (token: string, id: number) => {
    const bodyData = {
      evenementConnaissementEnum: 'DEMANDE',
      demandeParArmateur: false,
    }

    try {
      const response = await ConnaissementService.updateConnaissement(token, bodyData, id)
      // console.log(response)
      if (response.status === 200) {
        connaissementDataTable(currentPage, setTotalPages, itemPerPage)
        toggleShowUpdateSuccess()
        handleCloseUpdateToDemandeModal()
        setCheckedConnaissement([])
      }
    } catch (error: any) {
      console.log(error)
      setIsError({
        error: true,
        message: error?.response?.data?.error ? error?.response?.data?.error :  error?.message === "Network Error" ? "Oups, une erreur du côté de Revatua est survenue. Veuillez réessayer plus tard." : error?.message
      })
    }
  }
  
  const updateNbPaletteConnaissement = async (token: string, data: any) => {
    const connaissDetail = data.detailConnaissements?.map((prod: any) => {
      return {
        codeSH: prod?.codeSH.nomenclature,
        codeTarif: prod?.codeTarif.code,
        description: prod?.description,
        nbColis: prod?.nbColis,
        poids: prod?.poids,
        stockage: prod?.stockage,
        unitePoids: prod?.unitePoids,
      }
    })


    const bodyData = {
      "version": data?.version,
      "detailConnaissementDTO": connaissDetail,
      "expediteur": data?.expediteur,
      "destinataire": data?.destinataire,
      "paiement": data?.paiement,
      "numeroVoyage": data?.voyage?.numero,
      "ileDepart": data?.ileDepart.nom,
      "lieuDepart": data?.lieuDepart.nom,
      "ileArrivee": data?.ileArrivee.nom,
      "lieuArrivee": data?.lieuArrivee.nom,
      "nombreColisAEmbarquer": nbPalette

    }

    try {

      const response = await ConnaissementService.updateNbPalette(token, bodyData, data?.id)
      if (response.status === 200) {
        toggleShowUpdateSuccess()
        handleCloseUpdatePalette()
        setCheckedConnaissement([])
      }
    } catch (error: any) {
      console.log(error)
      const errorMessage =
        error?.message === 'Network Error'
          ? 'Une erreur est survenue. Veuillez vérifier votre connexion et réessayer plus tard.'
          : error?.response?.data?.error || error?.response?.data?.message

          const errorDetail = Array.isArray(error?.response?.data?.detail)
          ? error?.response?.data?.detail[0]
            ? ` : ${error?.response?.data?.detail[0]?.origine || ''} ${error?.response?.data?.detail[0]?.cause || ''}`
            : ''
          : typeof error?.response?.data?.detail === 'string'
            ? ` : ${error?.response?.data?.detail}`
            : '';
      setIsError({
        error: true,
        message: `${errorMessage || error?.message}${errorDetail}`
      })
   
    }
  }

  const deleteBrouillonConnaissement = async (token: string, id: number) => {
    setIsError({
      error: false,
      message: "",
    })
    try {
      const response = await ConnaissementService.deleteBrouillon(token, id)
      // console.log(response)
      if (response.status === 204) {
        connaissementDataTable(0, setTotalPages, itemPerPage)
        toggleShowDeleteSuccess()
        handleCloseDeleteModal()
      }
    } catch (error: any) {
      console.log(error)
      setIsError({
        error: true,
        message: error?.response?.data?.message,
      })
      toggleShowOrderError()
    }
  }

  //Gère les connaissements selectionnées
  const handleSelectConnaissement = (bill: any) => {
    if (checkedConnaissement.includes(bill)) {
      // ()
      setCheckedConnaissement(checkedConnaissement?.filter((item: any) => item !== bill))
    } else {
      setCheckedConnaissement([...checkedConnaissement, bill])
    }
  }

  const multiselected = (bill: any) => {
    if (checkedMultiConnaissement.includes(bill)) {
      setCheckedMultiConnaissement(checkedMultiConnaissement?.filter((item: any) => item?.id !== bill?.id))
    } else {
      setCheckedMultiConnaissement([...checkedMultiConnaissement, bill])
    }
  }



  // const [pdfData, setPdfData] = React.useState<any>();

  const getPDFConnaissementByEvenement = (token: string, 
   setPdfData: any ) => {
    try{
      const pdfResponses = checkedMultiConnaissement?.map((item: any) => {

        return ConnaissementServices.getPDFConnaissement(token, item?.id, item?.idEven, item?.num, setPdfData)
      })
      console.log(pdfResponses)
    }catch(error){
      console.log(error)
    }
  }


  //sélectionner tous les connaissements "BROUILLON"
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const validedData = connaissementData
      //   .filter((connaiss: any) => connaiss.dernierEtat.evenementConnaissement === 'BROUILLON')
        ?.map((item: any) => item.id)
        setCheckedConnaissement(validedData)
        // setCheckedConnaissement(connaissementData)
        setCheckedMultiConnaissement(connaissementData)
    } else {
      setCheckedConnaissement([])
      setCheckedMultiConnaissement([])
    }
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }


  const billOfilterProps = { handleSelectAll, filteringData, setFilteringData, isTransporter, filterConnaissement, isFiltering, isEmpty, isLoading}

  

//Modals props
  const detailOrderModalProps = {
    show,
    selectedConnaissement,
    handleClose,
    handlePrint,
    printRef,
  }
  const qrCodeModalProps = { showQrcode, selectedConnaissement, handleCloseQrcode }

  const updateToDemandeModalProps = {
    showUpdateToDemandeModal,
    handleCloseUpdateToDemandeModal,
    selectedConnaissement,
    updateBrouillonConnaissement,
    idsOrder,
    setIdsOrder,
    setIsError,
    toggleShowOrderError,
  }
  const updatePaletteModalProps = {
    showUpdatePalette,
    handleCloseUpdatePalette,
    selectedConnaissement,
    setSelectedConnaissement,
    setConnaissementData,
    connaissementData,
    setNbPalette,
    updateNbPaletteConnaissement
  }
  const updateMultiToDemandeModalProps = {
    showUpdateMultiToDemandeModal,
    handleCloseUpdateMultiToDemandeModal,
    checkedConnaissement,
    setCheckedConnaissement,
    connaissementDataTable,
    toggleShowUpdateSuccess,
    setIsError,
    toggleShowOrderError,
    idsOrder,
    setIdsOrder,
  }
  const deleteBillOfLadingProps = {
    showDeleteModal,
    handleCloseDeleteModal,
    selectedConnaissement,
    deleteBrouillonConnaissement,
    idsOrder, setIdsOrder,
    setIsError,
    toggleShowOrderError,
  }
  const toastUpdateUserSuccessProps = { showUpdateSuccess, toggleShowUpdateSuccess }
  const toastDeleteSuccessProps = { showDeleteSuccess, toggleShowDeleteSuccess }
  const toastErrorProps = {showOrderError, toggleShowOrderError, isError}; 
  const toastInfoProps = {showInfo, toggleShowInfo, infoOrder}; 

  return (
    <div className='p-1 p-sm-3'>
      <h3 className='text-secondary'>Connaissements</h3>
      <SearchBar handleFilterConnaissement={handleFilterConnaissement} />
      <Table striped hover responsive className='responsive-font-small border'>
        <BillOfTableTitle isTransporter={isTransporter} />
        <BillOfFilter billOfilterProps={billOfilterProps} />
        <tbody>
          {!isLoading &&
            connaissementData?.length > 0 &&
            connaissementData?.map((connaissement: any, indx: number) => {
              const isNotBrouillon =
                connaissement?.dernierEtat?.evenementConnaissement !== 'BROUILLON'

              const isRefusee =
                connaissement?.dernierEtat?.evenementConnaissement === 'DEMANDE_REFUSEE' ||
                connaissement?.dernierEtat?.evenementConnaissement === 'EMBARQUEMENT_REFUSE'

              const isDemande =
                connaissement?.dernierEtat?.evenementConnaissement === 'DEMANDE' ||
                connaissement?.dernierEtat?.evenementConnaissement === 'OFFICIALISE' ||
                connaissement?.dernierEtat?.evenementConnaissement ===
                  'OFFICIALISE_SOUS_RESERVE'

              const isRedBackGround = (connaissement?.dernierEtat?.evenementConnaissement ===
                'EMBARQUEMENT_REFUSE' ||
                connaissement?.dernierEtat?.evenementConnaissement ===
                  'DEMANDE_REFUSEE' || 
                  connaissement?.dernierEtat?.evenementConnaissement ===
                  'ANNULE')

              const isDangerous = connaissement.detailConnaissements.some(
                (detail: any) => detail.matiereDangereuse === true
              )

              const isFragile = connaissement.detailConnaissements.some(
                (detail: any) => detail.fragile === true
              )

              const dateDArrivee = connaissement?.voyage?.periple?.filter(
                (periple: any) => periple?.ileArrivee.nom === connaissement?.ileArrivee?.nom
              )[0]
              const ItemBillOfLadingProps = {
                indx,
                isRedBackGround,
                connaissement,
                checkedConnaissement,
                handleSelectConnaissement,
                multiselected,
                setSelectedConnaissement,
                handleShow,
                isTransporter,
                dateDArrivee,
                isDangerous,
                isFragile,
                isDemande,
                isRefusee,
                handleShowQrcode,
                isNotBrouillon,
                handleShowUpdateToDemandeModal,
                handleShowUpdatePalette,
                handleShowDeleteModal
          
              }    

              return (
                <tr key={indx} className=''>
                  <td
                    className={`p-1 p-sm-2 ${isRedBackGround && 'bg-danger'}`}
                    // onClick={() => {
                    //   if (isNotBrouillon) {
                    //     setInfoOrder(
                    //       'Impossible de sélectionner un connaissement qui n’est pas à l’état de brouillon'
                    //     )
                    //     toggleShowInfo()
                    //   }
                    // }}
                  >
                    <Form.Check
                      type='checkbox'
                      id={`${connaissement.id}`}
                      onChange={() => {
                        handleSelectConnaissement(connaissement?.id)
                        multiselected(connaissement)
                      }}
                      checked={
                        checkedConnaissement &&
                        checkedConnaissement?.includes(connaissement.id)
                      }
                      value={connaissement.id}
                      // disabled={isNotBrouillon}
                      required
                    />
                  </td>
                  <td
                    onClick={() => {
                      setSelectedConnaissement(connaissement)
                      handleShow()
                    }}
                    className='pointer p-1 pe-0 p-md-2'
                  >
                    {connaissement?.numero ? connaissement?.numero : connaissement?.id}{' '}
                  </td>
                  {isTransporter && (
                    <td
                      onClick={() => {
                        setSelectedConnaissement(connaissement)
                        handleShow()
                      }}
                      className='pointer p-1 pe-0 p-md-2'
                    >
                      {connaissement?.expediteur?.denomination}{' '}
                    </td>
                  )}
                  <td
                    onClick={() => {
                      setSelectedConnaissement(connaissement)
                      handleShow()
                    }}
                    className='pointer p-1 pe-0 p-md-2'
                  >
                    {connaissement?.destinataire?.denomination}{' '}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedConnaissement(connaissement)
                      handleShow()
                    }}
                    className='pointer p-1 pe-0 p-md-2'
                  >
                    {connaissement?.voyage?.nomNavire}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedConnaissement(connaissement)
                      handleShow()
                    }}
                    className='pointer p-1 pe-0 p-md-2 responsive-font-small'
                  >
                    <Tag
                      className='responsive-font-small'
                      size='sm'
                      color={_tagStatus(connaissement?.dernierEtat?.evenementConnaissement)}
                    >
                      {connaissement?.dernierEtat?.evenementConnaissement}
                      {/* {connaissement?.dernierEtatOfficialise?.evenementConnaissement} */}
                    </Tag>
                  </td>
                  <td
                    onClick={() => {
                      setSelectedConnaissement(connaissement)
                      handleShow()
                    }}
                    className='pointer p-1 pe-0 p-md-2'
                  >
                    {connaissement?.voyage?.dateDepart}
                  </td>
                  {!isTransporter && (
                    <>
                      <td
                        onClick={() => {
                          setSelectedConnaissement(connaissement)
                          handleShow()
                        }}
                        className='pointer p-1 pe-0 p-md-2'
                      >
                        {connaissement?.ileArrivee?.nom}
                      </td>
                      <td
                        onClick={() => {
                          setSelectedConnaissement(connaissement)
                          handleShow()
                        }}
                        className='pointer p-1 pe-0 p-md-2'
                      >
                        {dateDArrivee.dateArrivee}
                      </td>
                    </>
                  )}
                  <td className='pointer p-1 pe-0 p-md-2 text-center'>
                    <Row>
                      <Col xs={4}>
                        {isDangerous && (
                          <div>
                            <WarningTwoTone twoToneColor='#ee4848' />
                          </div>
                        )}
                        {isFragile && (
                          <div>
                            <WarningTwoTone twoToneColor='#fa8900' />
                          </div>
                        )}
                      </Col>
                      <Col xs={4}>
                        {isDemande && (
                          <span
                            className=''
                            onClick={() => {
                              setSelectedConnaissement(connaissement)
                              handleShowQrcode()
                            }}
                          >
                            <i className='ri-qr-code-line fs-4 me-2'></i>
                          </span>
                        )}
                      </Col>
                      {!isTransporter && (
                        <Col xs={4}>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant='transparent'
                              id='dropdown-basic'
                              className='border-0 no-chevron'
                            >
                              <b>
                                {' '}
                                <i className='ri-more-2-line'></i>
                              </b>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align='end'>
                              {isNotBrouillon && (
                                <Dropdown.Item
                                  className='d-flex align-items-center '
                                  onClick={() => {
                                    setSelectedConnaissement(connaissement)
                                    handleShowQrcode()
                                  }}
                                >
                                  <i className='ri-qr-code-line fs-4 me-2'></i> Qrcode
                                </Dropdown.Item>
                              )}
                              <Dropdown.Item
                                className='d-flex align-items-center '
                                onClick={() => {
                                  setSelectedConnaissement(connaissement)
                                  handleShow()
                                }}
                              >
                                <i className='ri-file-list-2-line text-info fs-4 me-2'></i>{' '}
                                Détail
                              </Dropdown.Item>
                              {!isNotBrouillon && (
                                <>
                                  <Dropdown.Item
                                    className='d-flex align-items-center '
                                    onClick={() => {
                                      handleShowUpdateToDemandeModal()
                                      setSelectedConnaissement(connaissement)
                                    }}
                                  >
                                    <i className='ri-check-line fs-4 me-2 text-success'></i>{' '}
                                    BROUILLON{' '}
                                    <i className='ri-arrow-right-line text-primary'></i>
                                    DEMANDE
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className='d-flex align-items-center '
                                    onClick={() => {
                                      handleShowUpdatePalette()
                                      setSelectedConnaissement(connaissement)
                                    }}
                                  >
                                    <i className='ri-pencil-line fs-4 me-2 text-warning'></i>{' '}
                                    Modifier{' '}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className='d-flex align-items-center '
                                    onClick={() => {
                                      handleShowDeleteModal()
                                      setSelectedConnaissement(connaissement)
                                    }}
                                  >
                                    <i className='ri-close-circle-line fs-4 me-2 text-danger'></i>{' '}
                                    Supprimer
                                  </Dropdown.Item>
                                </>
                              )}
                              {isRefusee && (
                                <Dropdown.Item
                                  className='d-flex align-items-center '
                                  onClick={() => {
                                    handleShowDeleteModal()
                                    setSelectedConnaissement(connaissement)
                                  }}
                                >
                                  <i className='ri-close-circle-line fs-4 me-2 text-danger'></i>{' '}
                                  Supprimer
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      )}
                    </Row>
                  </td>
                </tr>
              )
            })}

          {!isLoading && !isError.error && isFiltering && connaissementData?.length === 0 && (
           <NoResult />
          )}

          {!isLoading && isErrorConnaiss.error && (
           <ErrorConnaissementMessage isTransporter={isTransporter} isErrorConnaiss={isErrorConnaiss} />
          )}
        </tbody>
      </Table>
      <div className='d-flex align-items-center mb-5'>
        <PaginationZero
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
        <ItemsLimiter itemPerPage={itemPerPage} setItemPerPage={setItemPerPage} />
      </div>

      {isLoading && (
        <Container
          fluid
          className='bg-body-tertiary d-flex justify-content-center align-items-center vh-100 text-center'
        >
          <Spinner variant='primary' /> <span className='ms-3'>Chargement...</span>
        </Container>
      )}

      <DetailConnaissementModal detailOrderModalProps={detailOrderModalProps} />
      <QrcodeConnaissementModal qrCodeModalProps={qrCodeModalProps} />
      <UpdateToDemandeModal updateToDemandeModalProps={updateToDemandeModalProps} />
      <NbPaletteModal updatePaletteModalProps={updatePaletteModalProps} />
      <UpdateMultiToDemandeModal
        updateMultiToDemandeModalProps={updateMultiToDemandeModalProps}
      />
      <DeleteModal deleteBillOfLadingProps={deleteBillOfLadingProps} />

      <ToastUpdateUserSuccess toastUpdateUserSuccessProps={toastUpdateUserSuccessProps} />
      <ToastDeleteSuccess toastDeleteSuccessProps={toastDeleteSuccessProps} />
      <ToastError toastErrorProps={toastErrorProps} />
      <ToastInfo toastInfoProps={toastInfoProps} />
      {checkedConnaissement?.length > 1 && (
        <div
          className='p-1 p-sm-4 text-center border-bottom-0  printermulti'
          ref={printref}
        >

          {checkedMultiConnaissement.map((connaissement: any, index: number) => {
            const dateLastEvent =  new Date(connaissement?.dernierEtat?.dateEvenement.slice(0,10)).toLocaleDateString(
              'fr-FR',
              {
                timeZone: 'UTC',
              }
            ) + " " +
            selectedConnaissement?.dernierEtat?.dateEvenement.slice(11,19)
           return (
             <div key={index} className='multi-pdf-container'>
               <Container fluid>
                 <div className='d-flex-column d-sm-flex justify-content-around align-items-center'>
                   <div>
                     <Image src={dpam} width={80} height={80} />
                   </div>
                   <div>
                     <div className='fs-3'>{connaissement?.voyage?.nomNavire}</div>
                     <div className='font-75'>
                       BP {connaissement?.armateur?.boitePostale}{' '}
                       {connaissement?.armateur?.commune?.codePostal}{' '}
                       {connaissement?.armateur?.commune?.nom} <strong>Tél : </strong>{' '}
                       {connaissement?.armateur?.telephone}
                     </div>
                   </div>
                   <div>
                     <div className='d-flex justify-content-center w-100'>
                       {connaissement?.numero !== null && (
                         <QRCode
                           value={connaissement?.numero}
                           size={110}
                           bordered={true}
                           bgColor={'#ffffff'}
                         />
                       )}
                     </div>
                   </div>
                 </div>

                 <Row className='responsive-font-small mb-3'>
                   <Col
                     xs={12}
                     md={12}
                     className='d-flex-column m-auto p-0    text-center responsive-font-small'
                   >
                     <div className='mb-1'>
                       <strong className='fs-3'>CONNAISSEMENT</strong>
                     </div>

                     {connaissement?.dernierEtat?.evenementConnaissement}

                     {connaissement?.dernierEtat?.motif && (
                       <div className='mt-2'>
                         <strong>Motif</strong> : {connaissement?.dernierEtat?.motif}
                       </div>
                     )}
                   </Col>
                 </Row>
                 <Row className='responsive-font-small mb-3'>
                   <Col xs={5} className='m-auto text-start responsive-font-small mb-3 '>
                     <strong>Date</strong> :{' '}
                     {dateLastEvent}
                   </Col>
                   <Col xs={2} className='m-auto responsive-font-medium'></Col>
                   <Col xs={5} className='m-auto text-start responsive-font-small'>
                     {connaissement?.numero ? (
                       <span>
                         <strong> Numéro</strong> : {connaissement?.numero}{' '}
                       </span>
                     ) : (
                       <span> id : {connaissement?.id}</span>
                     )}
                     <div className='font-75'>
                       Référence chargeur
                       {connaissement?.referenceHorsRevatua?.split('|')?.length > 1 &&
                         `(${connaissement?.referenceHorsRevatua?.split('|')?.length})`}
                       : {connaissement?.referenceHorsRevatua}{' '}
                     </div>
                   </Col>
                 </Row>
                 <Container fluid className='mb-3'>
                   <Row className='responsive-font-small mb-3 border'>
                     <Col xs={4} className='m-auto text-start responsive-font-small'>
                       Navire :{' '}
                       {connaissement?.voyage?.periple &&
                         connaissement?.voyage?.periple[0]?.nomNavire}
                     </Col>
                     <Col xs={4} className='m-auto responsive-font-medium'>
                       Voyage n°: {connaissement?.voyage?.numero}
                     </Col>
                     <Col xs={4} className='m-auto text-end responsive-font-small'>
                       Départ: {connaissement?.voyage?.dateDepart}
                     </Col>
                   </Row>
                   <Row className='responsive-font-small mb-3 border'>
                     <Col xs={12} className='m-auto text-start responsive-font-small'>
                       <strong>Expéditeur</strong>: {connaissement?.expediteur?.denomination} -
                       N°Tahiti {connaissement?.expediteur?.numeroTahiti}
                     </Col>
                     <Col xs={12} className='m-auto text-start responsive-font-small'>
                       <strong>Ile de départ</strong>: {connaissement?.voyage?.ileDepart}{' '}
                       PAPEETE
                     </Col>
                     <Col xs={12} sm={6} className='m-auto text-start responsive-font-small'>
                       <strong>Téléphone</strong>: {connaissement?.expediteur?.telephone}
                     </Col>
                     <Col xs={12} sm={6} className='m-auto text-start responsive-font-small'>
                       <strong>Mail</strong>: {connaissement?.expediteur?.mail}
                     </Col>
                   </Row>
                   <Row className='responsive-font-small border'>
                     <Col xs={12} className='m-auto text-start responsive-font-small'>
                       <strong>Destinataire</strong>:{' '}
                       {connaissement?.destinataire?.denomination} - N°Tahiti{' '}
                       {connaissement?.destinataire?.numeroTahiti}
                     </Col>
                     <Col xs={12} className='m-auto text-start responsive-font-small'>
                       <strong>Ile de arrivée</strong>: {connaissement?.ileArrivee?.nom}
                     </Col>
                     <Col xs={12} sm={6} className='m-auto text-start responsive-font-small'>
                       <strong>Téléphone</strong>: {connaissement?.destinataire?.telephone}
                     </Col>
                     <Col xs={12} sm={6} className='m-auto text-start responsive-font-small'>
                       <strong>Mail</strong>: {connaissement?.destinataire?.mail}
                     </Col>
                   </Row>
                 </Container>
               </Container>
               <Container fluid>
                 <Table striped hover responsive className='border'>
                   <thead className='responsive-font-small'>
                     <tr>
                       <th className='text-start ps-1 ps-sm-2 pe-0 pe-sm-2'>Désignation</th>
                       <th className='text-end pe-0 pe-sm-2'>Nb Colis</th>
                       <th className='text-end pe-0 pe-sm-2'>Volume (m3)</th>
                       <th className='text-end pe-0 pe-sm-2'>Poids (Kg)</th>
                       <th className='text-end pe-1 pe-sm-2'>Montant</th>
                     </tr>
                   </thead>
                   <tbody className='responsive-font-small'>
                     {connaissement?.detailConnaissements?.map(
                       (product: any, indexProd: number) => {
                         return (
                           <tr key={indexProd}>
                             <td className='text-start ps-1 ps-sm-2 pe-0 pe-sm-2'>
                               {product?.description}
                             </td>
                             <td className='text-end pe-0 pe-sm-2'>{product?.nbColis}</td>
                             <td className='text-end pe-0 pe-sm-2'>
                               {product?.volume?.toFixed(3)}
                             </td>
                             <td className='text-end pe-0 pe-sm-2'>
                               {product?.poids?.toFixed(3)}
                             </td>
                             <td className='text-end pe-1 pe-sm-2'>
                               {_thousandSeparator(product?.montantDeclare, 'fr-FR')}
                             </td>
                           </tr>
                         )
                       }
                     )}
                     <tr>
                       {/* <td className='text- pe-3'>{connaissement?.nombreColisAEmbarquer}</td> */}
                       <td className='text-end'>Total </td>
                       <td className='text-end pe-0 pe-sm-2'>{_colisTotal(connaissement)}</td>
                       <td className='text-end pe-0 pe-sm-2'>
                         {_volumeTotal(connaissement)}{' '}
                       </td>
                       <td className='text-end pe-0 pe-sm-2'>
                         {_thousandSeparator(_totalWeight(connaissement), 'fr-FR')}
                       </td>
                       <td className='text-end pe-1 pe-sm-2'>
                         {_thousandSeparator(_totalMontant(connaissement), 'fr-FR')}
                       </td>
                     </tr>
                   </tbody>
                 </Table>
                 <Container>
                   <Row className='responsive-font-small'>
                     <Col
                       xs={0}
                       sm={4}
                       className='m-auto text-end responsive-font-small'
                     ></Col>
                     <Col xs={8} sm={4} className='m-auto text-start responsive-font-small'>
                       Mode de règlement :
                     </Col>
                     <Col xs={4} sm={4} className='m-auto text-start responsive-font-small'>
                       {connaissement?.paiement}
                     </Col>
                   </Row>
                   <Row className='responsive-font-small'>
                     <Col xs={0} sm={4} className=' text-end responsive-font-small'></Col>
                     <Col xs={8} sm={4} className=' text-start responsive-font-small'>
                       Prestations complémentaires :
                     </Col>
                     <Col xs={4} sm={4} className='m-auto text-start responsive-font-small'>
                       {connaissement?.prestationsComplementaires?.length === 0 ? (
                         'NON'
                       ) : (
                         <ul>
                           {connaissement?.prestationsComplementaires?.map(
                             (prestation: any, index: number) => (
                               <li key={index}>{prestation?.libelle}</li>
                             )
                           )}
                         </ul>
                       )}
                     </Col>
                   </Row>
                   <Row className='responsive-font-small'>
                     <Col
                       xs={0}
                       sm={4}
                       className='m-auto text-end responsive-font-small'
                     ></Col>
                     <Col xs={8} sm={4} className='m-auto text-start responsive-font-small'>
                       Nombre de suremballages :
                     </Col>
                     <Col xs={4} sm={4} className='m-auto text-start responsive-font-small'>
                       {connaissement?.nombreColisAEmbarquer}
                     </Col>
                   </Row>
                 </Container>
                 <Table responsive className='small-font'></Table>
               </Container>
               <div className='footer-connaissement'>
                 <div className='printermulti'>
                   REVATUA - Le {dateLastEvent} édition du connaissement n°
                   {connaissement?.numero}
                 </div>
                 <span className='page-number'></span>
               </div>
             </div>
           )
          }
          )}
        </div>
      )}
    </div>
  )

}
