import React from 'react'
import {
  Alert,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  ListGroup,
  Modal,
  Row,
  Table,
} from 'react-bootstrap'
import SearchTest from '../../../pages/private/SearchTest'
import OrdersService from '../../../services/orders/OrdersService'
import userStore from '../../../stores/userStore'
import {_tagStatus } from '../../../utils/functions'
import { Tag } from 'rsuite'
import { _handleAddproduct, _handleUpdateProduct } from '../../../utils/api/totaraApi'
import { debounce } from 'lodash'
import { errorType } from '../../../definitions/errorType'
import { usePDF, Margin } from 'react-to-pdf'
import { DetailOrderModalType, SearchPlanningModalType } from '../../../definitions/ComponentType'
import codeSH2023 from '../../../data/codeSH/codeSH2023.json'
import HeaderDetail from '../../billOfLading/detail/HeaderDetail'
import TableDetail from '../../billOfLading/detail/TableDetail'
import FooterDetail from '../../billOfLading/detail/FooterDetail'
import PrintFooterDetail from '../../billOfLading/detail/PrintFooterDetail'

export function SearchPlanningModal({ SearchPlanningModalProps }: {SearchPlanningModalProps: SearchPlanningModalType}) {
  const { showSearchPlanning, handleCloseSearchPlanning, searchPlanningProps } =
    SearchPlanningModalProps
  return (
    <Modal size='lg' show={showSearchPlanning} onHide={handleCloseSearchPlanning}>
      <SearchTest searchPlanningProps={searchPlanningProps} />
      <Modal.Footer className='border-0 sticky-bottom justify-content-start'>
        <Button variant='secondary' className='' onClick={handleCloseSearchPlanning}>
        <i className='ri-close-line me-2' ></i>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export function DetailOrderModal({ detailOrderModalProps }: {detailOrderModalProps: DetailOrderModalType}) {
  const stockageData = ['CALE', 'PONTEE', 'REFRIGERE', 'CONGELE']
  const codeTarifTab = ['FRIGO', 'FRIGODGAE', 'PPN','PGC', 'AUTRE' ]
  const contenantTab = [
    'BAC',
    'BIG BAG',
    'BONBONNE',
    'BOUTEILLE',
    'CAISSE',
    'CAMION GAZ',
    'CARTON',
    'CITERNE',
    'CONTENEUR',
    'CUBITAINER',
    'FARDEAU',
    'FUT',
    'GLACIERE',
    'ISOTENER',
    'MCM GAZ',
    'PALETTE',
    'POT',
    'RACK',
    'SAC',
    'SLING BAG',
    'TOUQUE',
    'VALISE',
  ]

  const {
    show,
    selectedOrder,
    handleClose,
    setSelectedOrder,
    dataOrder,
    setDataOrder,
    setInfoOrder,
    toggleShowInfo,
    setOrdersForConnaissement,
  } = detailOrderModalProps

  const dataStore = userStore((state: any) => state)
  const [validated, setValidated] = React.useState<boolean>(true);
  const [showUpdateProductModal, setShowUpdateProductModal] = React.useState<boolean>(false)

  const [isError, setIsError] = React.useState<errorType>({
    error: false,
    message: '',
  })
  const [sortedProductByCodeSH, setSortedProductByCodeSH] = React.useState<any>()
  const [selectedProduct, setSelectedProduct] = React.useState<any>()
  const [filteringData, setFilteringData] = React.useState<any>({
    detail_referenceExterne: '',
    detail_contenant: '',
    detail_description: '',
    detail_nbColis: '',
    detail_poids: '',
    detail_stockage: '',
    detail_codeTarif: '',
    detail_codeSH: '',
  })

  const codeSH = codeSH2023
  const [searchCodeSh, setSearchCodeSh] = React.useState<any>()
  const [filteredCodeSh, setFilteredCodeSh] = React.useState<any>([])


  const handleCloseUpdateProductModal = () => {
    setShowUpdateProductModal(false)
    setSelectedProduct({})
  }
  
  const handleShowUpdateProductModal = () => {
    setIsError({
      error: false,
      message: '',
    })
    setShowUpdateProductModal(true)
  }

  const [showAddProductModal, setShowAddProductModal] = React.useState<boolean>(false)

  const handleCloseAddProductModal = () => {
    setShowAddProductModal(false)
  }
  //Bloquer : Ajout produit à une commande existante
  // const handleShowAddProductModal = () => {
  //   setShowAddProductModal(true)
  //   setSelectedProduct({})
  // }

  React.useEffect(() => {
    if(selectedProduct?.detail_codeSH?.length > 2){

      const findingCodeSh: any = codeSH?.filter((sh: any) => {
        return sh?.code?.toString()?.includes(selectedProduct?.detail_codeSH)})
        setFilteredCodeSh(findingCodeSh)
      }
      if(selectedProduct?.detail_codeSH?.length === 0){
      setFilteredCodeSh([])
      }
  }, [selectedProduct?.detail_codeSH])
  

  React.useEffect(() => {
    setSortedProductByCodeSH(
      selectedOrder?.items?.sort((a: any, b: any) => a?.codeSH?.localeCompare(b?.codeSH))
    )
  }, [selectedOrder])

  //Editer une ligne de commande
  const handleSaveProductUpdates = (e: any) => {
    e.preventDefault()
    const form = e.currentTarget
    console.log(form.checkValidity())
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      const orderData = {
        detail_nbColis: selectedProduct.detail_nbColis,
        detail_contenant: selectedProduct.detail_contenant,
        detail_description: selectedProduct.detail_description,
        detail_codeSH: selectedProduct.detail_codeSH,
        detail_codeTarif: selectedProduct.detail_codeTarif,
        detail_stockage: selectedProduct.detail_stockage,
        detail_poids: selectedProduct.detail_poids,
        detail_referenceExterne: selectedProduct.detail_referenceExterne,
      }
      // Tableau d'origine
      const updatedProducts = selectedOrder.items.map((product: any) => {
        // Si l'ID du produit correspond à celui sélectionné, on le met à jour
        if (product?.detail_referenceExterne === selectedProduct?.detail_referenceExterne) {
          return {
            ...product,
            detail_nbColis: selectedProduct.detail_nbColis,
            detail_contenant: selectedProduct.detail_contenant,
            detail_description: selectedProduct.detail_description,
            detail_codeSH: selectedProduct.detail_codeSH,
            detail_codeTarif: selectedProduct.detail_codeTarif,
            detail_stockage: selectedProduct.detail_stockage,
            detail_poids: selectedProduct.detail_poids,
            detail_referenceExterne: selectedProduct.detail_referenceExterne, // On applique les changements
          }
        }
        return product // On retourne les autres produits inchangés
      })
      try {
        // Mise à jour de l'état selectedOrder avec les produits mis à jour
        // setSelectedOrder((prevOrder: any) => ({
        //   ...prevOrder,
        //   items: updatedProducts,
        // }))

        const updatedOrder = dataOrder?.map((order: any, indx: number) => {
          if (order.id === selectedOrder?.id) {
            return {
              ...order,
              items: updatedProducts,
            }
          }
          return order
        })

        // setDataOrder(updatedOrder)

        //Applique les changement en base de donnée
        _handleUpdateProduct(
          dataStore?.token,
          orderData,
          selectedProduct.id,
          setIsError,
          handleCloseUpdateProductModal,
          setSelectedOrder,
          updatedProducts,
          setDataOrder,
          updatedOrder
        )
        setOrdersForConnaissement([])
      } catch (error) {
        console.log(error)
      }
      // _getOrdersData2(dataStore?.token,
      //   currentPage,
      //   setDataOrder,
      //   setTotalPages,
      //   setIsLoading,
      //   setErrorOrderMessage)
      // Fermer le modal
    }
  }

  //Ajouter un produit à une commande existante
  const handleSaveProductAdd = (e: any) => {
    e.preventDefault()
    const orderData = {
      id_order: selectedOrder.id,
      detail_nbColis: selectedProduct.detail_nbColis,
      detail_contenant: selectedProduct.detail_contenant,
      detail_description: selectedProduct.detail_description,
      detail_codeSH: selectedProduct.detail_codeSH,
      detail_codeTarif: selectedProduct.detail_codeTarif,
      detail_stockage: selectedProduct.detail_stockage,
      detail_poids: selectedProduct.detail_poids,
      detail_unitePoids: 'KILO',
      detail_referenceExterne: selectedProduct.detail_referenceExterne,
    }

    const newTab = [...selectedOrder.items, orderData]

    setSelectedOrder((prevOrder: any) => ({
      ...prevOrder,
      items: newTab,
    }))

    _handleAddproduct(dataStore?.token, orderData)
    handleCloseAddProductModal()
  }

  //Fitrer detail produit

  const handlefilteredProduct = (e: any) => {
    const { name, value } = e.currentTarget
    const updatedFilteringData = {
      ...filteringData,
      [name]: value,
    }
    setFilteringData(updatedFilteringData)
    debouncedFilter(updatedFilteringData)
  }

  const debouncedFilter = debounce((updatedFilteringData) => {
    filteredProduct(dataStore.token, updatedFilteringData, selectedOrder?.id)
  }, 500) // 300ms delay

  const filteredProduct = async (token: any, filteringData: any, id: number|null) => {
    const isEmpty =
      filteringData?.detail_codeSH === '' &&
      filteringData?.detail_contenant === '' &&
      filteringData?.detail_codeTarif === '' &&
      filteringData?.detail_description === '' &&
      filteringData?.detail_nbColis === '' &&
      filteringData?.detail_poids === '' &&
      filteringData?.detail_referenceExterne === '' &&
      filteringData?.detail_stockage === '' &&
      filteringData?.detail_unitePoids === '' &&
      filteringData?.id === '' &&
      filteringData?.id_order === ''
    try {
      const response = await OrdersService.filteredItem(token, filteringData, id)
      console.log(response)
      if (response.data.data.length > 0 && !isEmpty) {
        const dataz = response.data.data?.filter((prod: any) => {
          return (
            prod.detail_codeSH,
            prod.detail_contenant,
            prod.detail_codeTarif,
            prod.detail_description,
            prod.detail_nbColis,
            prod.detail_poids,
            prod.detail_referenceExterne,
            prod.detail_stockage,
            prod.detail_unitePoids,
            prod.id,
            prod.id_order
          )
        })
        setSortedProductByCodeSH(dataz)
      } else {
        setSortedProductByCodeSH(
          selectedOrder?.items?.sort((a: any, b: any) => a?.codeSH?.localeCompare(b?.codeSH))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }



  return (
    <>
      <Modal size='lg' show={show} onHide={handleClose} className='px-0'>
        <Modal.Header className='text-center'>
          <Container fluid>
            <Row>
              <Col xs={12} className='m-auto responsive-font-medium  mb-3'>
                {selectedOrder?.destinataire?.denomination}
              </Col>

              <Col xs={4} className='m-auto text-start responsive-font-small'>
                <b>N° Facture : </b>
                {selectedOrder?.referenceHorsRevatua}
              </Col>
              <Col xs={4} className='m-auto responsive-font-medium mb-3'>
                {/* <strong>{selectedOrder?.statusRevatua}</strong> */}
                <Tag color={_tagStatus(selectedOrder?.statusRevatua)} className='mb-3'>
                  {selectedOrder?.statusRevatua}
                </Tag>
                <div>
                  <b>Paiement : </b>
                  {selectedOrder?.paiement}
                </div>
              </Col>
              <Col xs={4} className='m-auto text-end responsive-font-small'>
                <b> Date : </b> {selectedOrder?.date_creation}
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {/* <Button
              variant='secondary'
              className='mb-3'
              onClick={() => {
                if (
                  selectedOrder?.statusRevatua !== '"A_PLANIFIER"' &&
                  selectedOrder?.statusRevatua !== 'A_PLANIFIER' &&
                  selectedOrder?.statusRevatua !== 'BROUILLON'
                ) {
                  alert("Cette commande n'est plus modifiable")
                } else {
                  handleShowAddProductModal()
                }
              }}
            >
              + Ajouter un produit
            </Button> */}
            <Table striped hover responsive className='border '>
              <thead className='responsive-font-medium'>
                <tr>
                  <th style={{ width: '' }}>Réf.</th>
                  <th style={{ width: '' }}>Contenant</th>
                  <th style={{ width: '256px' }}>Désignation</th>
                  <th style={{ width: '' }}>Nb Colis</th>
                  <th style={{ width: '' }} className='text-end'>
                    Poids (Kg)
                  </th>
                  <th style={{ width: '' }}>Stockage</th>
                  <th style={{ width: '' }}>C.Tarif</th>
                  <th style={{ width: '' }} className='text-end'>
                    C.SH
                  </th>
                  <th style={{ width: '' }} className='text-center'>
                    Action
                  </th>
                </tr>
              </thead>
              <thead className='responsive-font-medium border-top-0'>
                <tr>
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='Référence'
                      name='detail_referenceExterne'
                      value={filteringData.detail_referenceExterne || undefined}
                      onChange={(e) => {
                        const detail_referenceExterne = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_referenceExterne: detail_referenceExterne,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>

                 
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='Contenant'
                      name='detail_contenant'
                      value={filteringData.detail_contenant || undefined}
                      onChange={(e) => {
                        const detail_contenant = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_contenant: detail_contenant,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='Désignation'
                      name='detail_description'
                      value={filteringData.detail_description || ''}
                      onChange={handlefilteredProduct}
                    />
                  </th>
                  <th>
                    {' '}
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='nbColis'
                      name='detail_nbColis'
                      value={filteringData.detail_nbColis || undefined}
                      onChange={(e) => {
                        const detail_nbColis = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_nbColis: detail_nbColis,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>
                  <th className='text-end'>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='Poids'
                      name='detail_poids'
                      value={filteringData.detail_poids || undefined}
                      onChange={(e) => {
                        const detail_poids = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_poids: detail_poids,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='Stockage'
                      name='detail_stockage'
                      value={filteringData.detail_stockage || ''}
                      onChange={(e) => {
                        const detail_stockage = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_stockage: detail_stockage,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='C.tarif'
                      name='detail_codeTarif'
                      value={filteringData.detail_codeTarif || ''}
                      onChange={(e) => {
                        const detail_codeTarif = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_codeTarif: detail_codeTarif,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>
                  <th>
                    <Form.Control
                      className=' border'
                      type='text'
                      autoComplete='on'
                      placeholder='C.SH'
                      name='detail_codeSH'
                      value={filteringData.detail_codeSH || ''}
                      onChange={(e) => {
                        const detail_codeSH = e?.currentTarget?.value
                        const updatedFilteringData = {
                          ...filteringData,
                          detail_codeSH: detail_codeSH,
                        }
                        setFilteringData(updatedFilteringData)
                        filteredProduct(
                          dataStore.token,
                          updatedFilteringData,
                          selectedOrder?.id
                        )
                      }}
                    />
                  </th>

                  <th className='text-center'></th>
                </tr>
              </thead>
              <tbody className='responsive-font-small'>
                {sortedProductByCodeSH?.map((product: any, indexProd: number) => {
                  const isMiissingData =
                    product?.detail_poids <= 0 ||
                    product?.detail_poids === undefined ||
                    product?.detail_stockage === '' ||
                    product?.detail_codeTarif === '' ||
                    product?.detail_codeSH === ''
                  return (
                    <tr key={indexProd}>
                      <td className={`text-${isMiissingData && 'danger'}`}>
                        {product?.detail_referenceExterne}
                      </td>
                      <td className={`text-${isMiissingData && 'danger'}`}>
                        {product?.detail_contenant}
                      </td>
                      <td className={`text-${isMiissingData && 'danger'}`}>
                        {product?.detail_description}
                      </td>
                      <td className={`text-end text-${isMiissingData && 'danger'}`}>
                        {product?.detail_nbColis}
                      </td>
                      <td className={`text-end text-${isMiissingData && 'danger'}`}>
                        {product?.detail_poids}
                      </td>
                      <td className={`text-${isMiissingData && 'danger'}`}>
                        {product?.detail_stockage}
                      </td>
                      <td className={`text-${isMiissingData && 'danger'}`}>
                        {product?.detail_codeTarif}
                      </td>
                      <td className={`text-${isMiissingData && 'danger'}`}>
                        {product?.detail_codeSH}
                      </td>
                      <td
                        className='text-center pointer'
                        onClick={() => {
                          if (
                            selectedOrder?.statusRevatua === 'A_PLANIFIER' ||
                            selectedOrder?.statusRevatua === 'BROUILLON'
                          ) {
                            setSelectedProduct(product)
                            handleShowUpdateProductModal()
                          } else {
                            setInfoOrder(
                              `Les commandes qui sont au statut ${selectedOrder?.statusRevatua} ne sont pas modifiables`
                            )
                            toggleShowInfo()
                          }
                        }}
                      >
                        <i className='ri-pencil-line fs-5'></i>{' '}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Container>
        </Modal.Body>
        <Modal.Footer className='sticky-bottom border-0'>
          <Button
            variant='secondary'
            onClick={() => {
              handleClose()
              setFilteringData({
                detail_description: '',
                detail_contenant: '',
                detail_nbColis: '',
                detail_referenceExterne: '',
                detail_poids: '',
                detail_stockage: '',
                detail_codeTarif: '',
                detail_codeSH: '',
              })
              // _getOrdersData2(
              //   dataStore?.token,
              //   currentPage,
              //   setDataOrder,
              //   setTotalPages,
              //   setIsLoading,
              //   setErrorOrderMessage
              // )
            }}
          >
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Product Modal bloqué*/}
      <Modal size='lg' show={showAddProductModal} onHide={handleCloseAddProductModal}>
        <Form onSubmit={handleSaveProductAdd}>
          <Modal.Header closeButton>
            <Modal.Title>Ajouter un produit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12} sm={6}>
                <FloatingLabel controlId='Code Article' label='Code Article' className='mb-3'>
                  <Form.Control
                    placeholder='Code Article'
                    type='text'
                    value={selectedProduct?.detail_referenceExterne}
                    onChange={(e) => {
                      const referenceExterne = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_referenceExterne: referenceExterne,
                      }))
                    }}
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12} sm={6}>
                <FloatingLabel controlId='Désignation' label='Désignation' className='mb-3'>
                  <Form.Control
                    placeholder='Désignation'
                    type='text'
                    value={selectedProduct?.detail_description}
                    onChange={(e) => {
                      const description = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_description: description,
                      }))
                    }}
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12} sm={4}>
                <FloatingLabel controlId='CodeTarifAdd' label='Code Tarif' className='mb-3'>
                  <Form.Control
                    placeholder='Code Tarif'
                    type='text'
                    value={selectedProduct?.detail_codeTarif}
                    onChange={(e) => {
                      const codeTarif = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_codeTarif: codeTarif,
                      }))
                    }}
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12} sm={4}>
                <FloatingLabel controlId='CodeSHAdd' label='Code SH' className='mb-3'>
                  <Form.Control
                    placeholder='Code SH'
                    type='text'
                    value={selectedProduct?.detail_codeSH}
                    onChange={(e) => {
                      const codeSH = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_codeSH: codeSH,
                      }))
                    }}
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12} sm={4}>
                <FloatingLabel controlId='Stockage' label='Stockage' className='mb-3'>
                  <Form.Select
                    name='stockage'
                    value={selectedProduct?.detail_stockage}
                    onChange={(e) => {
                      const stockage = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_stockage: stockage,
                      }))
                    }}
                    required
                  >
                    <option value='' className='text-ui-second'>
                      Choisir une zone de stockage
                    </option>
                    {stockageData?.map((stockage: any, index: any) => (
                      <option key={index} value={stockage}>
                        {stockage}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs={12} sm={6}>
                <FloatingLabel controlId='Qté' label='Qté' className='mb-3'>
                  <Form.Control
                    placeholder='Qté'
                    type='number'
                    value={selectedProduct?.detail_nbColis}
                    onChange={(e) => {
                      const qte = parseInt(e.currentTarget.value)
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_nbColis: qte,
                      }))
                    }}
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12} sm={6}>
                <FloatingLabel controlId='Poids' label='Poids (Kg)' className='mb-3'>
                  <Form.Control
                    placeholder='Poids (Kg)'
                    type='float'
                    min={0}
                    value={selectedProduct?.detail_poids}
                    onChange={(e) => {
                      const poids = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_poids: poids,
                      }))
                    }}
                    required
                  />
                </FloatingLabel>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseAddProductModal}>
              Annuler
            </Button>
            <Button variant='primary' type='submit'>
              Valider
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Update product (qty & poids) */}
      <Modal show={showUpdateProductModal} onHide={handleCloseUpdateProductModal}>
        <Form noValidate validated={validated} onSubmit={handleSaveProductUpdates}>
          <Modal.Header className='border-bottom-0' closeButton>
            <Modal.Title>
              <i className='ri-pencil-line fs-4 text-primary'></i> Editer une ligne article
            </Modal.Title>
          </Modal.Header>
          <div className='my-2'>
            <Row className='font-85 w-100 gx-0'>
              <Col className='ps-3' xs={3}>
                <b>Code article</b>
              </Col>
              <Col className='text-center'>
                <b>Désignation</b>
              </Col>
              <Col className='text-end pe-3' xs={3}>
                <b>Stockage</b>
              </Col>
            </Row>
            <Row className='font-85 w-100 gx-0 mb-3'>
              <Col className='ps-3' xs={3}>
                {selectedProduct?.detail_referenceExterne}
              </Col>
              <Col className='text-center'>{selectedProduct?.detail_description}</Col>
              <Col className='text-end pe-3' xs={3}>
                {selectedProduct?.detail_stockage}
              </Col>
            </Row>
            <Row className='font-85 w-100 gx-0'>
              <Col className='ps-3' xs={4}>
                <b>Contenant : </b>
                {selectedProduct?.detail_codeTarif}
              </Col>
              <Col className='ps-3' xs={4}>
                <b>Code tarif : </b>
                {selectedProduct?.detail_codeTarif}
              </Col>
              <Col className='text-end pe-3' xs={4}>
                <b>Code SH : </b>
                {selectedProduct?.detail_codeSH}
              </Col>
            </Row>
          </div>
          <Modal.Body>
            <Row>
              <Col xs={12}>
                <FloatingLabel controlId='nbcolis' label='nbcolis' className='mb-3'>
                  <Form.Control
                    name='detail_nbColis'
                    placeholder='nbcolis'
                    type='number'
                    min={0}
                    value={selectedProduct?.detail_nbColis || ''}
                    onChange={(e) => {
                      const qte = parseInt(e.currentTarget.value)
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_nbColis: qte,
                      }))
                    }}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Saisissez le nombre de colis
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs={12}>
                <FloatingLabel controlId='Contenant' label='Contenant' className='mb-3'>
                  <Form.Select
                    name='detail_contenant'
                    value={selectedProduct?.detail_contenant || ''}
                    onChange={(e) => {
                      const detail_contenant = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_contenant: detail_contenant,
                      }))
                    }}
                  >
                    <option value=''>Sélectionnez un contenant</option>
                    {contenantTab?.map((conttenant: any, index: any) => (
                      <option key={index} value={conttenant}>
                        {conttenant}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>
                    Sélectionnez un contenant
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs={12}>
                <FloatingLabel controlId='Poids' label='Poids (Kg)' className='mb-3'>
                  <Form.Control
                    name='detail_poids'
                    placeholder='Poids (Kg)'
                    type='float'
                    min={0}
                    value={selectedProduct?.detail_poids || ''}
                    onChange={(e) => {
                      const poids = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_poids: poids,
                      }))
                    }}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Saisissez le poids
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs={12}>
                <FloatingLabel controlId='Stockage' label='Stockage' className='mb-3'>
                  <Form.Select
                    name='detail_stockage'
                    value={selectedProduct?.detail_stockage || ''}
                    onChange={(e) => {
                      const detail_stockage = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_stockage: detail_stockage,
                      }))
                    }}
                    required
                  >
                    <option value=''>Sélectionner une zone de stockage</option>
                    {stockageData?.map((stockage: any, index: any) => (
                      <option key={index} value={stockage}>
                        {stockage}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>
                    Sélectionnez une zone de stockage
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs={12}>
                <FloatingLabel controlId='Code Tarif' label='Code Tarif' className='mb-3'>
                  <Form.Select
                    name='Code tarif'
                    value={selectedProduct?.detail_codeTarif || ''}
                    onChange={(e) => {
                      const detail_codeTarif = e.currentTarget.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_codeTarif: detail_codeTarif,
                      }))
                    }}
                    required
                  >
                    <option value=''>Sélectionner un code tarif</option>
                    {codeTarifTab?.map((codeT: any, index: any) => (
                      <option key={index} value={codeT}>
                        {codeT}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>
                    Sélectionnez un code tarif
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs={12} sm={12}>
                <FloatingLabel controlId='Code SH' label='Code SH' className='mb-3'>
                  <Form.Control
                    name='codeSH'
                    type='text'
                    autoComplete='on'
                    placeholder='Saisissez un Code SH'
                    value={selectedProduct?.detail_codeSH || ''}
                    onChange={(e) => {
                      const detail_codeSH = e.target.value
                      setSelectedProduct((prevData: any) => ({
                        ...prevData,
                        detail_codeSH: detail_codeSH,
                      }))
                      // setSearchCodeSh(e.target.value)
                    }}
                    required
                  />
                  {filteredCodeSh?.length > 0 && (
                    <ListGroup>
                      {filteredCodeSh?.map(
                        (sh: { code: string; description: string }, index: number) => (
                          <ListGroup.Item
                            action
                            variant='primary'
                            key={index}
                            onClick={(e) => {
                              const detail_codeSH = `${sh?.code}`
                              setSelectedProduct((prevData: any) => ({
                                ...prevData,
                                detail_codeSH: detail_codeSH,
                              }))
                              setSearchCodeSh(detail_codeSH)
                              setFilteredCodeSh([])
                            }}
                          >
                            {' '}
                            {sh?.code} : {sh?.description}
                          </ListGroup.Item>
                        )
                      )}
                    </ListGroup>
                  )}
                </FloatingLabel>
              </Col>
            </Row>
            <Alert
              show={isError?.error}
              variant='danger'
              className='d-flex align-items-center mt-3'
            >
              <i className='ri-error-warning-line fs-4 text-danger me-2'></i> {isError.message}
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                handleCloseUpdateProductModal()
                setValidated(true)
                setFilteredCodeSh([])
              }}
            >
              Annuler
            </Button>
            <Button variant='primary' type='submit'>
              Valider
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export function DetailConnaissementModal({ detailOrderModalProps }: any) {
  const { show, selectedConnaissement, handleClose, handlePrint, printRef } =
    detailOrderModalProps

  const [pdfData, setPdfData] = React.useState<any>()
    const [qrSize, setQrSize] = React.useState(110);


  const { toPDF, targetRef } = usePDF({
    method: 'save',
    filename: `${selectedConnaissement?.dernierEtat?.evenementConnaissement !== 'OFFICIALISE' && 'Aperçu-'}${selectedConnaissement?.numero ? selectedConnaissement?.numero : selectedConnaissement?.id}.pdf`,
    page: { margin: Margin.MEDIUM },
  })
  
  

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      // Mobile
      setQrSize(280); // Taille pour mobile
    } else {
      // Desktop
      setQrSize(110); // Taille pour desktop
    }
  };
 
 

  React.useEffect(() => {
    // Ajoute un listener pour détecter les changements de taille de fenêtre
    window.addEventListener('resize', handleResize);
    // Appelle la fonction une première fois pour définir la taille initiale
    handleResize();
    // Nettoie l'event listener quand le composant est démonté
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const footerDetailProps = {selectedConnaissement, toPDF, setPdfData, handlePrint, handleClose}


  return (
    <Modal size='lg' fullscreen='lg-down' show={show} onHide={handleClose} className='px-0'>
      <Modal.Body
        ref={printRef}
        className={`p-1 p-sm-4 text-center border-bottom-0 pdf-container ${
          selectedConnaissement?.dernierEtat?.evenementConnaissement !== 'OFFICIALISE'
            ? 'printer'
            : ''
        }`}
      >
        <div
          ref={targetRef}
          className={` ${
            selectedConnaissement?.dernierEtat?.evenementConnaissement !== 'OFFICIALISE'
              ? 'pdf-bg'
              : ''
          }`}
        >
            <HeaderDetail selectedConnaissement={selectedConnaissement} />

            <TableDetail selectedConnaissement={selectedConnaissement} />
          
          <PrintFooterDetail selectedConnaissement={selectedConnaissement} />
        </div>
      </Modal.Body>
     <FooterDetail footerDetailProps={footerDetailProps} />
    </Modal>
  )
}
