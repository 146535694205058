import React from 'react'
import { Image, Navbar } from 'react-bootstrap'
import tots from '../../styles/images/remora.png'
import userStore from '../../stores/userStore'
import MenuDrop from '../ui/MenuDrop'

export default function Header() {
  const dataStore = userStore((state: any) => state)
  const isTransporter = dataStore?.roles && dataStore.roles[0]?.name ==='transporteur'

  const [isDarkMode, setIsDarkMode] = React.useState<boolean>(false)

  const companyName =
    dataStore?.company &&
    dataStore?.company?.some((company: any) => company.name === 'CEDIS') &&
    dataStore?.company?.length === 11
      ? 'ALL COMPANY'
      : dataStore?.company?.length === 1
      ? dataStore?.company && dataStore?.company[0]?.name
      : dataStore?.company &&
        dataStore?.company?.length > 1 &&
        dataStore?.roles &&
        dataStore?.roles[0]?.name === 'transporteur'
      ? 'LOGIPOL'
      : ''

  React.useEffect(() => {
    if (isDarkMode) {
      document.body.setAttribute('data-bs-theme', 'dark')
    } else {
      document.body.setAttribute('data-bs-theme', 'light')
    }

    return () => {
      document.body.removeAttribute('data-bs-theme')
    }
  }, [isDarkMode])

  const menuDropProps = {isTransporter, isDarkMode, setIsDarkMode}


  return (
    <header className='sticky-top border-bottom '>
      <Navbar className='bg-body-tertiary px-3 py-0 py-md-2'>
        <Navbar.Brand className='d-flex align-items-center'>
          <Image src={tots} width={50} alt='logo' className='me-2' />
          <div className='responsive-font-small border border-secondary rounded-pill px-3'>
            {companyName}
          </div>
        </Navbar.Brand>
        <div className='d-flex ms-auto responsive-font-medium'>
          <div className='d-flex align-items-center me-2 me-sm-4'>{dataStore.name}</div>
          <MenuDrop menuDropProps={menuDropProps} />
        </div>
      </Navbar>
    </header>
  )
}
