import React from 'react'

export default function BillOfTableTitle({isTransporter}: any) {

  return (
    <thead>
      <tr>
        {!isTransporter && <th></th>}
        <th>
          N°<span className='d-none d-sm-inline'>Connaiss...</span>
        </th>
        {isTransporter && (
          <th>
            {/* Comp<span className='d-none d-sm-inline'>agnie</span>
            <span className='d-inline d-sm-none'>...</span> */}
            Société
          </th>
        )}
        <th>Client</th>
        <th>Navire</th>
        {!isTransporter && (
        <th>Statut</th>
        )}
        <th>Date départ</th>
        {!isTransporter && (
          <>
            <th>Ile d'arrivée</th>
            <th>Date Arrivée</th>
          </>
        )}
        <th>Action</th>
      </tr>
    </thead>
  )
}
