import React from 'react'
import {
  Form,
  Table,
  Container,
  Image,
  Spinner,
  Dropdown,
  Alert,
} from 'react-bootstrap'
import { ToastCurrentTrip, ToastInfo, ToastSendedBrouillon } from '../../component/ui/Toast/Toastes'
import { DetailOrderModal, SearchPlanningModal } from '../../component/ui/Modal/Modals'
import userStore, { UserState } from '../../stores/userStore'
import { useNavigate, useOutletContext } from 'react-router-dom'
import {
  _getOrdersData2,
  _tagStatus,
  _transformDataToNested,
} from '../../utils/functions'
import { Tag } from 'rsuite'
import { OrderType } from '../../definitions/OrderType'
import OrdersService from '../../services/orders/OrdersService'
import PaginationComponent from '../../component/ui/PaginationComponent'
import noResult from '../../styles/images/no_result.png'
import { _switchStatus } from '../../utils/api/totaraApi'
import OrderTableTitle from '../../component/orders/OrderTableTitle'
import OrderFilter from '../../component/orders/OrderFilter'
import PlanningButton from '../../component/orders/PlanningButton'
import ItemsLimiter from '../../component/billOfLading/ItemsLimiter'
import { DetailOrderModalType, filteringDataType, OrderFilterType, PlanningButtonType, SearchPlanningModalType, SearchPlanningType } from '../../definitions/ComponentType'
import NaviresService from '../../services/navires/NaviresService'
import { _refreshToken } from '../../utils/api/apiControlerFunctions'
import islandData from '../../data/iles/islandData.json'

export default function Order() {

  const { naviresData } = useOutletContext<any>()

  ///////////
  //store Data
  /////////////
  const dataStore = userStore((state: UserState) => state)
  const navigate = useNavigate()

  const isTransporter = dataStore?.roles && dataStore.roles[0]?.name ==='transporteur'



  
  //////////////
  //State
  /////////////
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isFiltering, setIsFiltering] = React.useState<boolean>(false)
  const [errorOrderMessage, setErrorOrderMessage] = React.useState<{
    error: boolean
    message: string
  }>({
    error: false,
    message: '',
  })
  const [infoOrder, setInfoOrder] = React.useState<string>("")

  const [dataOrder, setDataOrder] = React.useState<OrderType[]>([])
  const [selectedOrder, setSelectedOrder] = React.useState<OrderType>({} as OrderType)
  const [filteringData, setFilteringData] = React.useState<filteringDataType>({
    date_creation: '',
    referenceHorsRevatua: '',
    destinataire_denomination: '',
    numeroVoyage: '',
    statut_revatua: '',
    bateau: '',
    ileArrivee: '',
  })


  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const [itemPerPage, setItemPerPage] = React.useState<number>(20)

  // const [naviresData, setNaviresData] = React.useState<any>()



  const [ordersForConnaissement, setOrdersForConnaissement] = React.useState<OrderType[]>([])
// Toastes
  const [showA, setShowA] = React.useState<boolean>(false)
  const toggleShowA = () => setShowA(!showA)

  const [showBrouillon, setShowBrouillon] = React.useState<boolean>(false)
  const toggleShowBrouillon = () => setShowBrouillon(!showBrouillon)

  const [showErrorOrder, setShowErrorOrder] = React.useState<boolean>(false)
  const toggleShowErrorOrder = () => setShowErrorOrder(!showErrorOrder)

  const [showInfo, setShowInfo] = React.useState<boolean>(false)
  const toggleShowInfo = () => setShowInfo(!showInfo)

//Modals
  const [show, setShow] = React.useState(false)

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const [showSearchPlanning, setShowSearchPlanning] = React.useState(false)

  const handleCloseSearchPlanning = () => setShowSearchPlanning(false)
  const handleShowSearchPlanning = () => {
    if (ordersForConnaissement.length > 0) {
      setShowSearchPlanning(true)
    } else {
      toggleShowInfo()
      setInfoOrder("Veuillez sélectionner au moins une Facture")
    }
  }

  React.useEffect(() => {
    if (!dataStore?.token || dataStore?.token === undefined) {
      navigate('/connexion')
    }
    
    if(isTransporter){
      navigate('/connaissements-demande')
    }
  }, [])
  
  React.useEffect(() => {
    if (dataStore.token && dataStore.token !== "") {
      _getOrdersData2(
        dataStore?.token,
        currentPage,
        setDataOrder,
        setTotalPages,
        setIsLoading,
        setErrorOrderMessage,
        itemPerPage,
      )
      
    }
    
  }, [dataStore.token])

  React.useEffect(() => {
    if (isFiltering) {
      filteredOrder(dataStore?.token, filteringData, currentPage, itemPerPage)
    } else {
      _getOrdersData2(
        dataStore?.token,
        currentPage,
        setDataOrder,
        setTotalPages,
        setIsLoading,
        setErrorOrderMessage,
        itemPerPage
      )
    }
  }, [currentPage, itemPerPage])

  React.useEffect(() => {
    if (dataStore.access_token && dataStore.access_token !== "") {
      // _getShipments(dataStore.access_token, setNaviresData)
    }
  }, [dataStore.access_token])


 const _getShipments = async (
    token: string,
    setNaviresData: React.Dispatch<
      React.SetStateAction<{
        id: number
        name: string
      }[]>
    >
  ) => {
    try {
      const response = await NaviresService.getNaviresList(token)
      const navires = response.data?.map((nav: any) => {
        return {
          id: nav.id,
          name: nav.nom,
        }
      })
      setNaviresData(navires)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.error === 'invalid_token') {
        const idCompany = dataStore?.company && dataStore?.company[0]?.id_company
        _refreshToken(dataStore?.token, idCompany)
      }
    }
  }


  //Gère les factures selectionnées
  const handleSelectOrders = (order: OrderType) => {
    setOrdersForConnaissement((prevOrders: OrderType[]) => {
      if (prevOrders.includes(order)) {
        return prevOrders.filter((item) => item !== order);
      } else {
        return [...prevOrders, order];
      }
    });
 
  }

  //Gère la selection de toutes les factures
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const validOrders = dataOrder?.filter((order: OrderType) => {
        const isDifferentDestinataire =
          (ordersForConnaissement?.length > 0 &&
            ordersForConnaissement[0]?.destinataire?.denomination !==
              order?.destinataire?.denomination) ||
          (order?.statusRevatua !== 'A_PLANIFIER' && order?.statusRevatua !== 'BROUILLON')

        return !isDifferentDestinataire
      })

      if (validOrders?.length !== dataOrder?.length) {
        setInfoOrder("Vous devez sélectionner des factures avec le même client et un statut 'A_PLANIFIER' ou 'BROUILLON'")
        toggleShowInfo()
        setOrdersForConnaissement([])
      } else {
        setOrdersForConnaissement(validOrders)
      }
    } else {
      // Désélectionne toutes les commandes
      setOrdersForConnaissement([])
    }
  }
 

  const handlefilteredOrder = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    const updatedFilteringData = {
      ...filteringData,
      [name]: value,
    }
    setFilteringData(updatedFilteringData)
  }

  const filteredOrder = async (token: string | null, filteringData: filteringDataType, currentPage: number, itemPerPage: number) => {
    // Filtrer les paramètres qui ne sont pas définis ou sont vides
    const filteredParams = Object.entries(filteringData)
      .filter(([key, value]) => value) // Garde les paires où la valeur est définie (non null, non undefined, non vide)
      .map(([key, value]: [string, string]) => `${key}=${encodeURIComponent(value)}`) // Encode chaque paramètre
      .join('&') // Les concatène avec '&'
      setIsLoading(true)
      try {
        const response = await OrdersService.filteredOrder(token, filteredParams, 1, itemPerPage)
        console.log(response.data.data)
        setTotalPages(response.data.last_page) // Nombre total de pages
        setDataOrder(_transformDataToNested(response.data.data))
        setIsFiltering(true)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
        setIsFiltering(true)
        setIsLoading(false)
    }
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }
  

  const searchPlanningProps: SearchPlanningType = {
    ordersForConnaissement,
    setOrdersForConnaissement,
    handleCloseSearchPlanning,
    toggleShowA,
    toggleShowBrouillon,
    dataOrder,
    setDataOrder,
    naviresData,
    // islandData
  }
  const planningButtonProps: PlanningButtonType = { ordersForConnaissement, handleShowSearchPlanning }

  const toastCurrentTripProps = { showA, toggleShowA }
  const toastSendedBrouillonProps = { showBrouillon, toggleShowBrouillon }
  const toastInfoProps = {showInfo, toggleShowInfo, infoOrder};
  // const toastErrorOrderProps = {showErrorOrder, toggleShowErrorOrder, errorOrderMessage};

  const SearchPlanningModalProps: SearchPlanningModalType = {
    showSearchPlanning,
    handleCloseSearchPlanning,
    searchPlanningProps,
  }
  const detailOrderModalProps: DetailOrderModalType = {
    show,
    selectedOrder,
    handleClose,
    setSelectedOrder,
    dataOrder,
    setDataOrder,
    setErrorOrderMessage,
    setIsLoading,
    currentPage,
    setTotalPages,
    setInfoOrder,
    toggleShowInfo,
    setOrdersForConnaissement,
    itemPerPage,
  }
  const orderFilterProps: OrderFilterType = {
    handleSelectAll,
    dataOrder,
    ordersForConnaissement,
    handlefilteredOrder,
    filteringData,
    setFilteringData,
    filteredOrder,
    isFiltering,
    setIsFiltering,
    currentPage,
    setDataOrder,
    setTotalPages,
    isLoading,
    setIsLoading,
    setErrorOrderMessage,
    naviresData,
    itemPerPage,
  }
  // console.log(dataOrder)

  return (
    <div className='p-3 pb-5 mb-5'>
      <h3 className='text-secondary'>Factures</h3>
      <Table striped hover responsive className=' border'>
        <OrderTableTitle />
        <OrderFilter orderFilterProps={orderFilterProps} />
        <tbody>
          {(!errorOrderMessage.error || !isLoading) &&
            dataOrder?.length > 0 &&
            dataOrder?.map((order: OrderType, indx: number) => {
              const isDifferentDestinataire =
                ordersForConnaissement?.length > 0 &&
                ordersForConnaissement[0]?.destinataire?.denomination !==
                  order?.destinataire?.denomination
              const isNotBrouillon =
                order?.statusRevatua !== 'A_PLANIFIER' && order?.statusRevatua !== 'BROUILLON'
              return (
                <tr key={indx}>
                  <td
                    className='p-1 p-sm-2'
                    onClick={() => {
                      if (isDifferentDestinataire) {
                        setInfoOrder(
                          'Vous ne pouvez pas sélectionner des factures avec des destinataires différents'
                        )
                        toggleShowInfo()
                      } else if (isNotBrouillon) {
                        setInfoOrder('Cette facture ne peut pas être sélectionnée.')
                        toggleShowInfo()
                      }
                    }}
                  >
                    {' '}
                    {order?.date_creation !== undefined && (
                      <Form.Check
                        type='checkbox'
                        id={`${order.id}`}
                        onChange={() => {
                          handleSelectOrders(order)
                        }}
                        checked={
                          ordersForConnaissement && ordersForConnaissement?.includes(order)
                        }
                        value={order?.referenceHorsRevatua}
                        disabled={isDifferentDestinataire || isNotBrouillon}
                        required
                      />
                    )}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2 '
                  >
                    {order?.dateFacture !== undefined && order?.dateFacture}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2 '
                  >
                    <span className='truncate'>{order?.referenceHorsRevatua} </span>
                  </td>

                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  >
                    {order?.destinataire?.denomination}{' '}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  >
                    {order?.numeroVoyage}
                  </td>
                  <td
                    onClick={() => {
                      if (
                        order?.statusRevatua !== 'A_PLANIFIER' &&
                        order?.statusRevatua !== 'A_DEPLANIFIER'
                      ) {
                        setSelectedOrder(order)
                        handleShow()
                      }
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  >
                    {' '}
                    <Tag
                      size='sm'
                      className={
                        order?.statusRevatua === 'A_DEPLANIFIER'
                          ? 'bg-secondary text-light responsive-font-small border hover-parent'
                          : 'responsive-font-small  border hover-parent'
                      }
                      color={_tagStatus(order?.statusRevatua)}
                    >
                      {order?.statusRevatua === 'A_PLANIFIER' ||
                      order?.statusRevatua === 'A_DEPLANIFIER' ? (
                        <Dropdown className=''>
                          <Dropdown.Toggle
                            className='border-0 p-0'
                            variant='transparent'
                            id='update_status'
                          >
                            <span className='font-75'>{order?.statusRevatua}</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className='responsive-font-small'
                              onClick={() => {
                                if (order?.statusRevatua === 'A_PLANIFIER') {
                                  _switchStatus(
                                    dataStore.token,
                                    'A_DEPLANIFIER',
                                    order.id,
                                    setErrorOrderMessage,
                                    dataOrder,
                                    setDataOrder,
                                    setShowErrorOrder
                                  )
                                } else {
                                  _switchStatus(
                                    dataStore.token,
                                    'A_PLANIFIER',
                                    order.id,
                                    setErrorOrderMessage,
                                    dataOrder,
                                    setDataOrder,
                                    setShowErrorOrder
                                  )
                                }
                              }}
                            >
                              {order?.statusRevatua === 'A_PLANIFIER'
                                ? 'A_DEPLANIFIER'
                                : 'A_PLANIFIER'}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <>{order?.statusRevatua}</>
                      )}
                    </Tag>
                  </td>
                  {/* <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  >
                    {order?.items?.[0]?.detail_stockage}
                  </td> */}
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  >
                    {order?.navire}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  >
                    {order?.ileArrivee}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  ></td>
                </tr>
              )
            })}
          {!isLoading && dataOrder?.length === 0 && (
            <tr className=''>
              <td></td>
              <td colSpan={7} className='text-center'>
                <Image src={noResult} height={32} /> Votre recherche n'a donné aucun résultat
              </td>
              <td></td>
            </tr>
          )}
          {!isLoading && errorOrderMessage?.error && (
            <tr className=''>
              <td colSpan={9} className='text-center'>
                <Alert variant='danger'>
                  <i className='ri-error-warning-line fs-2 text-light  me-3'></i>
                  {errorOrderMessage?.message}
                </Alert>
              </td>
              <td></td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className='d-flex align-items-center mb-5'>
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
        <ItemsLimiter itemPerPage={itemPerPage} setItemPerPage={setItemPerPage} />
      </div>

      {isLoading && (
        <Container
          fluid
          className='bg-body-tertiary d-flex justify-content-center align-items-center vh-100 text-center'
        >
          <Spinner variant='primary' /> <span className='ms-3'>Loading...</span>
        </Container>
      )}

      <PlanningButton planningButtonProps={planningButtonProps}/>

      <SearchPlanningModal SearchPlanningModalProps={SearchPlanningModalProps} />
      <DetailOrderModal detailOrderModalProps={detailOrderModalProps} />

      <ToastSendedBrouillon toastSendedBrouillonProps={toastSendedBrouillonProps} />
      <ToastCurrentTrip toastCurrentTripProps={toastCurrentTripProps} />
      <ToastInfo toastInfoProps={toastInfoProps} />
    </div>
  )
}
