import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { TransporterTableType } from '../../definitions/ComponentType';



export default function TransporterTable({setSelectedConnaissement, connaissement, handleShow, handleShowQrcode}: TransporterTableType) {

  return (
    <tr className=''>
    <td
      onClick={() => {
        setSelectedConnaissement(connaissement)
        handleShow()
      }}
      className='pointer p-1 pe-0 p-md-2'
    >
      {connaissement?.numero ? connaissement?.numero : connaissement?.id}{' '}
    </td>
   
      <td
        onClick={() => {
          setSelectedConnaissement(connaissement)
          handleShow()
        }}
        className='pointer p-1 pe-0 p-md-2'
      >
        {connaissement?.expediteur?.denomination}{' '}
      </td>
    
    <td
      onClick={() => {
        setSelectedConnaissement(connaissement)
        handleShow()
      }}
      className='pointer p-1 pe-0 p-md-2'
    >
      {connaissement?.destinataire?.denomination}{' '}
    </td>
    <td
      onClick={() => {
        setSelectedConnaissement(connaissement)
        handleShow()
      }}
      className='pointer p-1 pe-0 p-md-2'
    >
      {connaissement?.voyage?.nomNavire}
    </td>
    {/* <td
      onClick={() => {
        setSelectedConnaissement(connaissement)
        handleShow()
      }}
      className='pointer p-1 pe-0 p-md-2 responsive-font-small'
    >
      <Tag
        className='responsive-font-small'
        size='sm'
        color={_tagStatus(connaissement?.dernierEtat?.evenementConnaissement)}
      >
        {connaissement?.dernierEtat?.evenementConnaissement}
      </Tag>
    </td> */}
    <td
      onClick={() => {
        setSelectedConnaissement(connaissement)
        handleShow()
      }}
      className='pointer p-1 pe-0 p-md-2'
    >
      {connaissement?.voyage?.dateDepart}
    </td>

    <td className='pointer p-1 pe-0 p-md-2 text-center'>
      <Row>
        <Col>
            <span
              className=''
              onClick={() => {
                setSelectedConnaissement(connaissement)
                handleShowQrcode()
              }}
            >
              <i className='ri-qr-code-line fs-4 me-2'></i>
            </span>
        </Col>
      </Row>
    </td>
  </tr>
  )
}
