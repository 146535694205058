import React from 'react'
import { Alert, Button, FloatingLabel, Form, Modal, Spinner } from 'react-bootstrap'
import userStore from '../../stores/userStore'
import CompaniesService from '../../services/companies/CompaniesService'
import { set } from 'date-fns'
import { _getCompaniesData } from '../../utils/api/totaraApi'
import { errorType } from '../../definitions/errorType'

export default function ModalAddCompany(modalAddUserProps: any) {
 
    const { show, handleClose, setShowA, setCompaniesData, setIsLoading } = modalAddUserProps

    const dataStore = userStore((state: any) => state)
    const [isLoadingCompany, setIsLoadingCompany] = React.useState<boolean>(false)
    const [isError, setIsError] = React.useState<errorType>({
      error: false,
      message: ''
    })
  
    const [companyData, setCompanyData] = React.useState<any>({
      id_company: null,
      name: '',
      client_id: '',
      client_secret: '',
      username: '',
      scope: 'email profile',
    })
  
    const roleTab = ['user', 'shipper', 'admin', 'super_admin']
  
    const handleAddCompny = async (e: React.SyntheticEvent) => {
      e.preventDefault()
      setIsLoadingCompany(true)
      setIsError({
        error: false,
        message: ''
      })
      try{

        const response = await CompaniesService.addCompany(dataStore.token, companyData)
  
        if (response) {
          handleClose()
          setIsLoadingCompany(false)
          setShowA(true)
          _getCompaniesData(dataStore.token, setCompaniesData, setIsLoading)
          setCompanyData({
            id_company: null,
            name: '',
            client_id: '',
            client_secret: '',
            username: '',
            scope: 'email profile',
          })
          setShowA(true)
        }

      }catch(error: any){
        setIsLoadingCompany(false)
      const messageError = JSON.parse(error?.request?.response)?.data.id_company ? JSON.parse(error?.request?.response)?.data.id_company : error?.response?.data?.message
        setIsError({
          error: true,
          message: error?.response?.data?.message + " : " + messageError || error?.message || 'Une erreur est survenue'
        })
      }
    }

    // console.log(companyData)
  
    return (
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleAddCompny}>
          <Modal.Header className='border-bottom'>
            <Modal.Title>Ajouter une compagnie</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FloatingLabel controlId='company_id' label='id_company *' className='mb-3'>
              <Form.Control
                className='border border-1 border-secondary mb-3'
                name='id'
                type='number'
                autoComplete='on'
                placeholder='Id_company *'
                value={companyData?.id}
                onChange={(e) => {
                  const id_Company = parseInt(e?.currentTarget?.value)

                  setCompanyData((prevData: any) => ({
                    ...prevData,
                    id_company: id_Company,
                  }))
                }}
                required
              />
            </FloatingLabel>
            <FloatingLabel controlId='company_name' label='Nom *' className='mb-3'>
              <Form.Control
                className='border border-1 border-secondary mb-3'
                name='name'
                type='text'
                autoComplete='on'
                placeholder='Nom *'
                value={companyData?.name}
                onChange={(e) => {
                  const name = e?.currentTarget?.value

                  setCompanyData((prevData: any) => ({
                    ...prevData,
                    name: name,
                  }))
                }}
                required
              />
            </FloatingLabel>
            <FloatingLabel controlId='client_id' label='client_id' className='mb-3'>
              <Form.Control
                className='border border-1 border-secondary mb-3'
                name='userApi'
                type='text'
                autoComplete='on'
                placeholder='client_id'
                value={companyData?.client_id}
                onChange={(e) => {
                  const client_id = e?.currentTarget?.value

                  setCompanyData((prevData: any) => ({
                    ...prevData,
                    client_id: client_id,
                  }))
                }}
               
              />
            </FloatingLabel>

            <FloatingLabel controlId='client_secret' label='client_secret' className='mb-3'>
              <Form.Control
                className='border border-1 border-secondary mb-3'
                name='client_secret'
                type='text'
                autoComplete='on'
                placeholder='client_secret'
                onChange={(e) => {
                  const client_secret = e?.currentTarget?.value

                  setCompanyData((prevData: any) => ({
                    ...prevData,
                    client_secret: client_secret,
                  }))
                }}
              />
            </FloatingLabel>
            <FloatingLabel controlId='username' label='username' className='mb-3'>
              <Form.Control
                className='border border-1 border-secondary mb-3'
                name='username'
                type='text'
                autoComplete='on'
                placeholder='username'
                onChange={(e) => {
                  const username = e?.currentTarget?.value

                  setCompanyData((prevData: any) => ({
                    ...prevData,
                    username: username,
                  }))
                }}
              />
            </FloatingLabel>
            <Alert show={isError.error} variant='danger' className='d-flex align-items-center mt-3'>
          <i className='ri-close-circle-line fs-4 text-danger me-2'></i>
          <span className='font-85'>
           {isError.message}
            </span> 
          
          </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              className='bg-remora-secondary border-remora-secondary'
              onClick={() => {
                handleClose()
                setCompanyData({
                  id_company: null,
                  name: '',
                  client_id: '',
                  client_secret: '',
                  username: '',
                  scope: 'email profile',
                })
              }}
            >
              Annuler
            </Button>
            <Button variant='primary'
              className='bg-remora-primary border-remora-primary'
              type='submit'>
            {isLoadingCompany ? (
              <>
                <Spinner variant='light' size='sm' /> Loading
              </>
            ) : (
              <>
                <i className='ri-community-line'></i> Ajouter
              </>
            )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }